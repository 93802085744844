import { StyledButton } from "./styles"
import { Box, Typography } from "@mui/material"
import React, { useCallback } from "react"
import { ButtonProps } from "./types"
import { generatePath, useHistory } from "react-router-dom"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner"
import { default as general_eancode_image } from "../../assets/images/general_eancode.png"
import { barcodeIconBase64, isAte } from "utils/Global"
import { StyledBox, StyledImage } from "components/Image/styles"
import { scrolltToTop } from "utils/util"

const Button: React.FC<ButtonProps> = (props) => {
  const {
    hidden,
    text,
    path,
    isDisabled,
    type,
    onClick,
    isForwardIcon,
    isScannIcon,
    isBarcode,
    isOvverride,
    isFlexFlow,
    width,
  } = props
  const history = useHistory()

  const handleClick = useCallback(() => {
    if (isDisabled || type !== undefined) {
      return
    } else if (path.includes("http")) {
      const win = window.open(path, "_blank")
      if (win != null) {
        win.focus()
      }
    } else if (path?.length > 0) {
      scrolltToTop()
      const currentPath = history.location.pathname
      history.push({
        pathname: generatePath(path),
        previous: currentPath,
      })
    }
  }, [history, path, isDisabled, type])

  const isOnClickOvverride = useCallback(() => {
    if (isDisabled || type !== undefined) {
      return
    } else if (path.includes("http")) {
      const win = window.open(path, "_blank")
      if (win != null) {
        win.focus()
      }
    } else if (path?.length > 0) {
      scrolltToTop()
      const currentPath = history.location.pathname
      sessionStorage.setItem("imageVas", general_eancode_image)
      history.push({
        pathname: generatePath(path),
        previous: currentPath,
      })
    }
  }, [history, path, isDisabled, type])

  const checkProps = () => {
    scrolltToTop()
    if (isOvverride) return isOnClickOvverride
    if (onClick) return onClick
    else return handleClick
  }

  return (
    <Box
      key={"box" + text}
      sx={{ display: hidden ? "none" : "initial" }}
      width={isFlexFlow ? "20%" : "50%"}
    >
      <div
        key={"div" + text}
        style={{
          textDecoration: "none",
          width: width,
          marginLeft: "auto",
          marginRight: "auto",
        }}
        onClick={checkProps()}
      >
        <StyledButton
          type={type}
          fullWidth
          variant="contained"
          sx={{ height: isFlexFlow && "32px", color: isAte && "white" }}
        >
          <Typography marginRight="10px">{text}</Typography>
          {isForwardIcon && (
            <ArrowForwardIosIcon style={{ fontSize: "15px" }} />
          )}
          {isScannIcon && <QrCodeScannerIcon />}
          {isBarcode && (
            <StyledBox>
              {" "}
              <StyledImage
                height="27px"
                width="27px"
                src={barcodeIconBase64}
              />{" "}
            </StyledBox>
          )}
          {/* {isBarcode && <Image size={{width: 27, height:27}} image={barcodeIconBase64} />} */}
        </StyledButton>
      </div>
    </Box>
  )
}

export default Button
