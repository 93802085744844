import { styled } from "@mui/material/styles"
import Background from "../Background"

export const StyledBackground = styled(Background)(({ theme }) => ({
  overflowY: "auto",
  overflowX: "hidden",
  paddingInline: theme.spacing(4),
  [theme.breakpoints.down("sm")]: {
    paddingInline: theme.spacing(0),
  },
}))
