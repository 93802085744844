import * as React from "react"
import { observer } from "mobx-react"

import { Box, Typography, useTheme } from "@mui/material"
import { useEffect } from "react"
import { useTecDocStore } from "api/services/TecDoc"
import { useMemo } from "react"
import useScopedTranslation from "hooks/useScopedTranslation"
import en from "./locales/en.json"
import List, { ListImageItemTypes } from "pages/TecDoc/components/List"
import ListHeading from "pages/TecDoc/components/List/components/ListHeading"

export const namespace = __filename

const Documents: React.FC = () => {
  const tecDocStore = useTecDocStore()
  let { articleDocuments, dynamicIpAdded } = tecDocStore

  const theme = useTheme()
  const { t } = useScopedTranslation(
    namespace,
    (locale) => import(`./locales/${locale}.json`),
    en
  )

  const getClientIp = async (): Promise<string> => {
    try {
      return fetch("https://api.ipify.org?format=json")
        .then((response) => {
          return response.json()
        })
        .then((res) => {
          return res.ip
        })
        .catch((err: any) => console.error("Problem fetching my IP", err))
    } catch (error) {
      try {
        return fetch(
          "https://geolocation-db.com/json/d802faa0-10bd-11ec-b2fe-47a0872c6708"
        )
          .then((response) => {
            return response.json()
          })
          .then((res) => {
            return res.IPv4
          })
          .catch((err: any) => console.error("Problem fetching my IP", err))
      } catch (error) {
        throw new Error("Problem fetching my IP")
      }
    }
  }

  useEffect(() => {
    if (!dynamicIpAdded) {
      getClientIp().then((ip) => {
        tecDocStore
          .addPublicIP(ip)
          .catch((err) => console.error("Error fetching articles", err))
      })
    }
  }, [dynamicIpAdded, tecDocStore])

  const brandItems = useMemo(() => {
    let tempDocumentDetails: ListImageItemTypes[] = []

    if (articleDocuments && dynamicIpAdded) {
      tempDocumentDetails = articleDocuments.map((item) => {
        return {
          key: item.docId,
          subItems: [
            {
              content: `https://webservice.tecalliance.services/pegasus-3-0/documents/22148/${item.docId}/0`,
              docType: item.docFileTypeName,
              docTypeName: item.docTypeName,
            },
          ],
        }
      })
    }
    return tempDocumentDetails
  }, [articleDocuments, dynamicIpAdded])

  return (
    <Box>
      <Box sx={{ paddingBlock: theme.spacing(2) }}>
        <ListHeading title={t("title")} caret={"NONE"} variant={"PRIMARY"} />
      </Box>

      {articleDocuments && articleDocuments.length > 0 ? (
        <List type={"IMAGE"} title={t("images")} items={brandItems} />
      ) : (
        <Typography variant="body1">{t("noData")}</Typography>
      )}
    </Box>
  )
}

export default observer(Documents)
