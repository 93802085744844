import React, { useEffect, useState, useRef } from "react"
import styles from "./styles.module.css"
import "@georapbox/resize-observer-element/dist/resize-observer-defined"
import { CapturePhoto } from "@georapbox/capture-photo-element"
import { BarcodeFormat, BrowserMultiFormatReader } from "@zxing/library"
import "barcode-detector"

CapturePhoto.defineCustomElement()
const formats = ["ean_13"]
const zxingReader = new BrowserMultiFormatReader()
const barcodeDetector = new window.BarcodeDetector({ formats })

export function EanScannerIos({ onResult }) {
  const capturePhotoEl = useRef(null)
  const zoomLevelEl = useRef(null)
  const zoomControls = useRef(null)
  const scanFrameContainer = useRef(null)
  const scanFrameEl = useRef(null)
  const defineScript = useRef(null)

  const [capturePhotoVideoEl, setCapturePhotoVideoEl] = useState(null)
  const [scanResult, setScanResult] = useState(null)
  const [prevScannedCode, setPrevScannedCode] = useState(Infinity)
  const [prevScannedTime, setPrevScannedTime] = useState(0)
  const [resetScanIndicator, setResetScanIndicator] = useState(0)
  const [rafId, setRafId] = useState(null)
  const [shouldRepeatScan, setShouldRepeatScan] = useState(true)

  useEffect(() => {
    if (!defineScript.current || !capturePhotoEl.current) return

    capturePhotoEl.current.addEventListener(
      "capture-photo:video-play",
      handleCameraPlay,
      { once: true }
    )
    capturePhotoEl.current.addEventListener(
      "capture-photo:error",
      handleCameraError,
      { once: true }
    )

    return () => {
      capturePhotoEl.current.removeEventListener(
        "capture-photo:video-play",
        handleCameraPlay
      )
      capturePhotoEl.current.removeEventListener(
        "capture-photo:error",
        handleCameraError
      )
    }
  }, [defineScript, capturePhotoEl])

  const handleCameraPlay = (evt) => {
    const newCapturePhotoVideoEl =
      capturePhotoEl.current.shadowRoot.querySelector("video")
    setCapturePhotoVideoEl(newCapturePhotoVideoEl)
    resizeScanFrame(evt.detail.video)
    scan()

    zxingReader.decodeFromVideoDevice(
      0,
      newCapturePhotoVideoEl,
      (result, err) => {
        if (result && result.format == BarcodeFormat.EAN_13) {
          setScanResult({ ean_code: result.text })
        }
      }
    )

    const trackSettings = capturePhotoEl.current.getTrackSettings()
    const trackCapabilities = capturePhotoEl.current.getTrackCapabilities()

    if (trackSettings?.zoom && trackCapabilities?.zoom) {
      const minZoom = trackCapabilities?.zoom?.min || 0
      const maxZoom = trackCapabilities?.zoom?.max || 10
      let currentZoom = trackSettings?.zoom || 1
      if (maxZoom >= 2) {
        currentZoom = 2
      }
      capturePhotoEl.current.zoom = currentZoom

      zoomControls.current.hidden = false
      zoomLevelEl.current.textContent = currentZoom

      zoomControls.current.addEventListener("click", (evt) => {
        const zoomInBtn = evt.target.closest('[data-action="zoom-in"]')
        const zoomOutBtn = evt.target.closest('[data-action="zoom-out"]')

        if (zoomInBtn && currentZoom < maxZoom) {
          currentZoom += 0.5
        }

        if (zoomOutBtn && currentZoom > minZoom) {
          currentZoom -= 0.5
        }

        zoomLevelEl.current.textContent = currentZoom

        capturePhotoEl.current.zoom = currentZoom
      })
    }
  }

  const handleCameraError = (evt) => {
    const error = evt.detail.error

    if (error.name === "NotFoundError") {
      // If the browser cannot find all media tracks with the specified types that meet the constraints given.
      return
    }

    const errorMessage =
      error.name === "NotAllowedError"
        ? "Permission to use webcam was denied or video Autoplay is disabled. Reload the page to give appropriate permissions to webcam."
        : error.message

    scanFrameContainer.current.innerHTML = /* html */ `<div class="alert alert-danger" role="alert" style="margin: 0;">${errorMessage}</div>`
  }

  const resizeScanFrame = (videoEl) => {
    if (!videoEl) {
      return
    }

    const rect = videoEl.getBoundingClientRect()

    scanFrameEl.current.style.cssText = `width: ${rect.width}px; height: ${rect.height}px`
  }

  async function scan() {
    try {
      let barcode = {}
      barcode = await detectBarcode(capturePhotoVideoEl)
      window.cancelAnimationFrame(rafId)

      setScanResult({ ean_code: barcode.rawValue })
    } catch (err) {
      // fail quietly
    }

    if (shouldRepeatScan) {
      setRafId(window.requestAnimationFrame(() => scan()))
    }
  }

  const detectBarcode = (source) => {
    return new Promise((resolve, reject) => {
      barcodeDetector
        .detect(source)
        .then((results) => {
          if (Array.isArray(results) && results.length > 0) {
            resolve(results[0])
          } else {
            reject({
              message: "Could not detect barcode from provided source.",
            })
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  useEffect(() => {
    if (!shouldRepeatScan || !scanResult) {
      return
    }

    if (resetScanIndicator !== null) {
      window.clearTimeout(resetScanIndicator)
      setResetScanIndicator(null)
    }
    scanFrameEl.current.querySelector("path").style.stroke = "limegreen"
    const newResetScanIndicator = window.setTimeout(() => {
      scanFrameEl.current.querySelector("path").style.stroke = "white"
    }, 750)
    setResetScanIndicator(newResetScanIndicator)

    if (prevScannedCode !== scanResult.ean_code) {
      setPrevScannedCode(scanResult.ean_code)
      setPrevScannedTime(Date.now())
      return
    } else if (Date.now() - prevScannedTime <= 500) {
      return
    }
    setPrevScannedCode(Infinity)

    onResult(scanResult.ean_code)
    return
  }, [scanResult])

  return (
    <div className={styles["container"]}>
      <div className={styles["scan-frame-container"]} ref={scanFrameContainer}>
        <resize-observer>
          <capture-photo
            facing-mode="environment"
            no-image
            auto-play
            ref={capturePhotoEl}
          >
            <button
              type="button"
              id="scanBtn"
              className={styles["scan-button"]}
              hidden
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z" />
              </svg>
              Click to scan another barcode.
            </button>

            <span slot="facing-mode-button-content" title="Switch camera">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={styles["ionicon"]}
                viewBox="0 0 512 512"
                width="22"
                height="22"
              >
                <path
                  d="M350.54 148.68l-26.62-42.06C318.31 100.08 310.62 96 302 96h-92c-8.62 0-16.31 4.08-21.92 10.62l-26.62 42.06C155.85 155.23 148.62 160 140 160H80a32 32 0 00-32 32v192a32 32 0 0032 32h352a32 32 0 0032-32V192a32 32 0 00-32-32h-59c-8.65 0-16.85-4.77-22.46-11.32z"
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="32"
                />
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="32"
                  d="M124 158v-22h-24v22M335.76 285.22v-13.31a80 80 0 00-131-61.6M176 258.78v13.31a80 80 0 00130.73 61.8"
                />
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="32"
                  d="M196 272l-20-20-20 20M356 272l-20 20-20-20"
                />
              </svg>
              <span className={styles["visually-hidden"]}>Switch camera</span>
            </span>

            <span slot="capture-button" hidden></span>

            <div slot="actions">
              <div
                className={styles["zoom-controls"]}
                id="zoomControls"
                ref={zoomControls}
                hidden
              >
                <button type="button" title="Zoom out" data-action="zoom-out">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    fill="currentColor"
                    className={`${styles["bi"]} ${styles["bi-zoom-out"]}`}
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                    />
                    <path d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z" />
                    <path
                      fillRule="evenodd"
                      d="M3 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"
                    />
                  </svg>
                </button>

                <label id="zoomLevel" ref={zoomLevelEl}></label>

                <button type="button" title="Zoom in" data-action="zoom-in">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    fill="currentColor"
                    className={`${styles["bi"]} ${styles["bi-zoom-in"]}`}
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                    />
                    <path d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z" />
                    <path
                      fillRule="evenodd"
                      d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </capture-photo>
          <script ref={defineScript}></script>
        </resize-observer>

        <div id="scanFrame" className={styles["scan-frame"]} ref={scanFrameEl}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              d="M336 448h56a56 56 0 0056-56v-56M448 176v-56a56 56 0 00-56-56h-56M176 448h-56a56 56 0 01-56-56v-56M64 176v-56a56 56 0 0156-56h56"
              fill="none"
              stroke="var(--ean-ios-scan-frame-color)"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="10"
            />
          </svg>
        </div>
      </div>
    </div>
  )
}
