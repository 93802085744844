import React from "react"
import ReactDOM from "react-dom"

import App from "App"
import * as serviceWorker from "serviceWorker"
import { isDevEnv } from "utils/env"

/**
 * THIS IS A HOTFIX TO PREVENT CSS CONFLICTS WITH STYLESHEETS COMING FROM PRIMING CLOUD.
 */

const conflictList = ["css/styles", "material", "materialize"]
const conflictLinkedStyleSheets = Array.from(
  document.getElementsByTagName("link")
).filter((link) =>
  conflictList.some((conflictItem) => link.href.includes(conflictItem))
)
const conflictStyleSheets = Array.from(
  document.getElementsByTagName("style")
).filter((link) => link.textContent.includes("PrimingCloud"))

for (const styleSheet of [
  ...conflictLinkedStyleSheets,
  ...conflictStyleSheets,
]) {
  try {
    document.head.removeChild(styleSheet)
  } catch {
    if ((styleSheet as HTMLLinkElement).href) {
      ;(styleSheet as HTMLLinkElement).href = ""
    }
  }
}

/**
 * END OF HOTFIX.
 */

if (isDevEnv()) {
  document.title = `${document.title} (localhost)`

  // activate mobx logger
  require("mobx-logger").enableLogging({
    action: true,
    reaction: false,
    transaction: true,
    compute: false,
  })
}

const render = (App: any) =>
  ReactDOM.render(<App />, document.getElementById("root"))

render(App)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default
    render(NextApp)
  })
}
