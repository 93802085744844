import { styled } from "@mui/material/styles"
import { Button } from "@mui/material"

export const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  padding: "10px 25px 10px 25px",
  backgroundColor: theme.tokens.color.primary,
  color: theme.tokens.color.conti_typo_button,
  borderRadius: "40px",
  [theme.breakpoints.down("md")]: {
    fontSize: theme.spacing(1.6),
  },
  "&:hover": {
    backgroundColor: "grey",
    color: "#fff",
  },
}))
