import * as React from "react"
import { observer } from "mobx-react"

import { Box, useTheme } from "@mui/material"

import useScopedTranslation from "hooks/useScopedTranslation"
import en from "./locales/en.json"
import { useTecDocStore } from "../../../../api/services/TecDoc"
import { useMemo } from "react"
import List, { ListTextItemTypes } from "pages/TecDoc/components/List"
import { createItemBorder, uniq } from "pages/util"
import ListHeading from "pages/TecDoc/components/List/components/ListHeading"
export const namespace = __filename

const Info: React.FC = () => {
  const theme = useTheme()
  const { t } = useScopedTranslation(
    namespace,
    (locale) => {
      return import(`./locales/${locale}.json`)
    },
    en
  )
  const tecDocStore = useTecDocStore()
  const {
    articleList,
    articleAttributes,
    directArticle,
    eanNumber,
    replacedNumber,
  } = tecDocStore

  const generalArticleItems = useMemo(() => {
    const requiredKeys = [
      "articleName",
      "articleNo",
      "articleStateName",
      "eanNumber",
      "replacedNumber",
    ]
    let tempArticleList: ListTextItemTypes[] = []
    let tempDirectArticleList: ListTextItemTypes[] = []
    let tempEanNumber: ListTextItemTypes[] = []
    let tempReplacedNumberList: ListTextItemTypes[] = []

    if (articleList) {
      tempArticleList = Object.entries(articleList).map((item) => {
        return {
          key: item[0],
          subItems: [
            {
              label: requiredKeys.includes(item[0]) ? t(item[0]) : item[0],
              content: String(item[1]),
              docType: item[1]?.docFileTypeName,
            },
          ],
        }
      })
    }
    if (directArticle) {
      tempDirectArticleList = Object.entries(directArticle).map((item) => {
        return {
          key: item[0],
          subItems: [
            {
              label: requiredKeys.includes(item[0]) ? t(item[0]) : item[0],
              content: String(item[1]),
              docType: item[1]?.docFileTypeName,
            },
          ],
        }
      })
    }
    if (eanNumber) {
      tempEanNumber = Object.entries(eanNumber).map((item) => {
        return {
          key: Object.keys(item[1])[0],
          subItems: [
            {
              label: t(Object.keys(item[1])[0]),
              content: String(item[1]?.eanNumber),
              docType: item[1]?.docFileTypeName,
            },
          ],
        }
      })
    }

    if (replacedNumber) {
      tempReplacedNumberList = Object.entries(replacedNumber).map((item) => {
        return {
          key: Object.keys(item[1])[0],
          subItems: [
            {
              label: Object.keys(item[1])[0],
              content: String(item[1]?.replacedNumber),
              docType: item[1]?.docFileTypeName,
            },
          ],
        }
      })
    }

    const tempGeneralArticleList = tempArticleList
      .concat(tempDirectArticleList, tempEanNumber, tempReplacedNumberList)
      .filter((item) => requiredKeys.includes(item.key))

    return uniq(tempGeneralArticleList)
  }, [articleList, directArticle, eanNumber, replacedNumber, t])

  const criteriaItems = useMemo(() => {
    let tempArticleAttributes: ListTextItemTypes[] = []
    if (articleAttributes) {
      tempArticleAttributes = Object.entries(articleAttributes).map((item) => {
        return {
          key: item[1]?.attrName,
          subItems: [
            {
              label: item[1]?.attrName,
              content: item[1]?.attrValue,
              docType: item[1]?.docFileTypeName,
            },
          ],
        }
      })
    }
    return tempArticleAttributes
  }, [articleAttributes])

  return (
    <Box>
      <Box sx={{ paddingBlock: theme.spacing(2), color: "white" }}>
        <ListHeading title={t("title")} caret={"NONE"} variant={"PRIMARY"} />
      </Box>
      <List
        type={"TEXT"}
        title={t("general")}
        items={createItemBorder(generalArticleItems)}
      />
      <List
        type={"TEXT"}
        title={t("criteria")}
        items={createItemBorder(criteriaItems)}
      />
    </Box>
  )
}

export default observer(Info)
