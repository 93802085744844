import React from "react"
import { Box, BoxProps } from "@mui/material"

// eslint-disable-next-line
import svgURL from "!!url-loader!./images/osapiens-dark.svg"
import useTheme from "@mui/material/styles/useTheme"
import { StyledBackground } from "./styles"

interface BackgroundProps {}

const Background: React.FC<BoxProps & BackgroundProps> = (props) => {
  const { children } = props
  const { palette } = useTheme()
  const backgroundPattern =
    process.env.REACT_APP_API_LAYOUT === "osapiens"
      ? {
          backgroundImage: `url(${svgURL})`,
          backgroundRepeat: "repeat",
        }
      : {}

  return (
    <Box style={{ background: palette.background.default }}>
      <StyledBackground {...props} style={backgroundPattern}>
        {children}
      </StyledBackground>
    </Box>
  )
}

export default Background
