import React from "react"

import { StyledListHeading } from "./styles"
import { Typography, useTheme } from "@mui/material"
import { ListHeadingTypes } from "../types"
import Icon from "@mdi/react"
import { mdiChevronDown, mdiChevronUp } from "@mdi/js"
import tokens from "themes/default/tokens"
import { isAte } from "utils/Global"
import { convertSizeRem } from "utils/util"

const ListHeading: React.FC<ListHeadingTypes> = (props) => {
  const { title, caret, variant } = props
  const theme = useTheme()

  return (
    <StyledListHeading
      background={
        variant === "PRIMARY" ? tokens.color.primary : `rgb(205, 205, 205)`
      }
      theme={theme}
    >
      <Typography
        sx={{ fontSize: "16px", fontWeight: 600 }}
        color={isAte ? (variant === "PRIMARY" ? "white" : "black") : "black"}
      >
        {title}
      </Typography>
      {caret !== "NONE" && (
        <Icon
          path={caret === "DOWN" ? mdiChevronDown : mdiChevronUp}
          size={convertSizeRem(2)}
          style={{ marginLeft: "auto" }}
        />
      )}
    </StyledListHeading>
  )
}

export default ListHeading
