import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"

const StyledAccordionList = styled(Box)<{
  display: string
}>`
  display: ${(props) => props.display};
`

export { StyledAccordionList }
