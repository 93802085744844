import React, { createContext, useContext } from "react"

import Store from "./store"

/* 
CONTEXT / PROVIDER INIT
*/

const AppStoreContext = createContext<Store | null>(null)

export const StoreProvider: React.FC = (props) => {
  const { children } = props

  return (
    <AppStoreContext.Provider value={new Store()}>
      {children}
    </AppStoreContext.Provider>
  )
}

/* 
HOOK DEFINITION
*/

export const useAppStore = () => useContext(AppStoreContext)
