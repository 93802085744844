import { isDevEnv } from "utils/env"

export const call = async <T>(
  method: string,
  params: {} & any,
  customer: string = process.env.REACT_APP_API_CUSTOMER,
  username: string = process.env.REACT_APP_API_USERNAME,
  password: string = process.env.REACT_APP_API_PASSWORD,
  timeout: number = 60000
): Promise<T> => {
  const host = isDevEnv()
    ? process.env.REACT_APP_API_HOST_PROD
    : process.env.REACT_APP_API_HOST
  const application = process.env.REACT_APP_API_APPLICATION
  try {
    return fetch(
      `https://${host}/data/integration/${customer}/${application}/${method}?application=${application}&customer=${customer}`,
      {
        method: "POST",
        mode: "cors",
        cache: "default",
        headers: {
          ouname: username,
          password: password,
        },
        body: JSON.stringify(params),
      }
    ).then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      return response.json() as Promise<T>
    })
  } catch (e) {
    throw new Error(`Error in call: ${e}`)
  }
}

export enum APIMethods {
  TECDOC_GETCOUNTRIES = "getCountries",
  TECDOC_GETARTICLE = "getArticleDirectSearchAllNumbersWithState",
  TECDOC_GETBRANDDETAILS = "getAmBrandAddress",
  TECDOC_GETARTICLEDETAILS = "getDirectArticlesByIds6",
  TECDOC_ADDIPADDRESS = "addDynamicAddress",
  TECDOC_GETVEHICLELIST = "getArticleLinkedAllLinkingTarget3",
  TECDOC_GETVEHICLEDETAILSLIST = "getVehicleByIds4",
  TECDOC_GETVEHICLELISTIMMEDIATEATTRIBUTS = "getArticleLinkedAllLinkingTargetsByIds3",
}
