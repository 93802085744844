// import React from "react"
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import i18nCountries from "i18n-iso-countries"
import { cloneDeep } from "lodash"
import moment from "moment"
import "moment/locale/de"

import de from "./locales/de.json"
import en from "./locales/en.json"
import es from "./locales/es.json"
import fr from "./locales/fr.json"
import it from "./locales/it.json"
import pl from "./locales/pl.json"
import pt from "./locales/pt.json"
import ro from "./locales/ro.json"
import tr from "./locales/tr.json"
import zh from "./locales/zh.json"
import ko from "./locales/ko.json"

export interface Language {
  locale: string
  name: string
  icon: JSX.Element
}

const getBrowserLanguage = () => {
  // @ts-ignore
  const userLang = navigator.language || navigator.userLanguage
  return userLang ? userLang.split("-")[0] : FALLBACK_LANGUAGE
}

const browserLanguage = getBrowserLanguage()
export const FALLBACK_LANGUAGE = "en"

export const defaultTranslationModules = [
  { locale: "de", texts: de },
  { locale: "en", texts: en },
  { locale: "es", texts: es },
  { locale: "fr", texts: fr },
  { locale: "it", texts: it },
  { locale: "pl", texts: pl },
  { locale: "pt", texts: pt },
  { locale: "ro", texts: ro },
  { locale: "tr", texts: tr },
  { locale: "zh", texts: zh },
  { locale: "ko", texts: ko },
]
export const defaultLanguages = defaultTranslationModules.map((m) => m.locale)

const resources = cloneDeep(
  Object.fromEntries(
    defaultTranslationModules.map((m) => [m.locale, { app: m.texts }])
  )
)

// register defaultLanguages for country names
defaultLanguages.forEach((locale) =>
  i18nCountries.registerLocale(
    require(`i18n-iso-countries/langs/${locale}.json`)
  )
)

i18n
  .use(LanguageDetector)

  // pass the i18n instance to react-i18next.
  .use(initReactI18next)

  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    debug: process.env.NODE_ENV === "development",
    ns: ["app"],
    defaultNS: "app",
    lng: browserLanguage || FALLBACK_LANGUAGE,
    fallbackLng: FALLBACK_LANGUAGE,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      bindI18n: "languageChanged",
      bindI18nStore: "added",
    },
  })

moment.locale(i18n.language)

let SETUP_COMPLETED = {
  [FALLBACK_LANGUAGE]: false,
}
export const isSetup = (language: string) => SETUP_COMPLETED[language]
export const setup = (language: string) => {
  if (isSetup(language) === false) {
    i18nCountries.registerLocale(
      require(`i18n-iso-countries/langs/${language}.json`)
    )
  }
}

export default i18n
