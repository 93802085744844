import React, { useEffect, useRef, useState } from "react"
import { observer } from "mobx-react"
import {
  Box,
  CircularProgress,
  Container,
  Typography,
  useTheme,
} from "@mui/material"

import CheckResultFlags from "./components/CheckResultFlags"
import { useAuthenticityCheckStore } from "api/services/AuthenticityCheck"
import ResponsiveBackground from "../../components/ResponsiveBackground"
import useScopedTranslation from "../../hooks/useScopedTranslation"
import en from "./locales/en.json"
import { SearchParameters } from "../../types/BusinessObjects"
import { isUndefined } from "../../utils/util"
import { checkGTIN, convertToHRF, getGeoLocation, isNumeric } from "./util"
import * as applicationIdentifiers from "./ai.json"
import Chip from "@mui/material/Chip"
import { styled } from "@mui/system"
import ContiSpecificButton from "components/ContiSpecificButton"
import { default as Conti_vehicle_use } from "../../assets/images/CONTI_Loyalty_Program_Werbetrailer_sec22.jpg"
import { default as Ate_vehicle_use } from "../../assets/images/ATE_Loyalty_Program_Werbetrailer_sec22.jpg"
import { basePath, isAte } from "utils/Global"
import { globalEndpoint } from "types/Global"

// use this namespace also for subcomponents and for smaller subpages
export const namespace = __filename

const BlurredBackground = styled("div")({
  position: "relative",
  width: "100%",
  height: "200px",
  overflow: "hidden",
  background: `url(${
    !isAte ? Conti_vehicle_use : basePath + Ate_vehicle_use
  }) center/cover`,
  "&:before": {
    content: "''",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    filter: "blur(5px)",
    backgroundColor: "rgba(0, 0, 0, 0.6)", // Adjust the alpha value (last parameter) for darkness
  },
})

const OverlayContent = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
})

const AuthenticityCheck = () => {
  const { t, i18n } = useScopedTranslation(
    namespace,
    (locale) => import(`./locales/${locale}.json`),
    en
  )
  const theme = useTheme()
  const path = window.location.pathname.split("#")[0]
  const search: SearchParameters =
    window.location.search &&
    window.location.search
      .slice(1)
      .split("&")
      .map((p) => p.split("="))
      .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})

  const lang = navigator.language.slice(0, 2)
  const [productCode, setProductCode]: any = useState()
  const [latitude, setLatitude] = useState(null)
  const [longitude, setLongitude] = useState(null)
  const [geoLocationStatus, setGeoLocationStatus] = useState("INIT")
  const [endpoint, setEndpoint] = useState(globalEndpoint)
  const loading = useRef(false)

  const authenticityCheckStore = useAuthenticityCheckStore()
  const { loadingAuthenticityCheckList, authenticityCheckList } =
    authenticityCheckStore

  const links = {
    en: "https://www.continental-aftermarket.com/en-en/contact-authenticity-check-and-vehicle-use",
    de: "https://www.continental-aftermarket.com/de-de/kontakt-originalitaetspruefung-und-fahrzeugverwendung",
    fr: "https://www.continental-aftermarket.com/fr-fr/contacter-le-controle-doriginalite-et-lutilisation-du-vehicule",
    es: "https://www.continental-aftermarket.com/es-es/contacto-control-de-originalidad-y-uso-del-vehiculo",
    it: "https://www.continental-aftermarket.com/it-it/contatto-controllo-originalita-e-utilizzo-veicolo",
  }

  const getQuerySearchReferrer = (ref) => {
    let queryString = ref.split("?")[1]
    if (queryString) {
      return queryString
    } else {
      return console.error("no query search params on referrer")
    }
  }
  getQuerySearchReferrer(window.document.referrer)

  const searchReferrer =
    getQuerySearchReferrer(window.document.referrer) &&
    getQuerySearchReferrer(window.document.referrer)
      .split("&")
      .map((p) => p.split("="))
      .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})

  const extractCode = (uid) => {
    if (uid.includes("%5dd2")) {
      let stringBuilder = ""
      const codeArray = convertToHRF(uid, applicationIdentifiers)
      codeArray.forEach((ai) => {
        stringBuilder = stringBuilder + `(${ai.name})${ai.value}`
      })
      uid = stringBuilder
    } else if (uid.includes("%28") && uid.includes("%29")) {
      uid = uid.replaceAll("%28", "(").replaceAll("%29", ")")
    }
    if (checkGTIN(uid)) return uid
    else return null
  }

  useEffect(() => {
    setProductCode(sessionStorage.getItem("productCode"))
    return () => {
      sessionStorage.removeItem("productCode")
    }
  }, [])

  useEffect(() => {
    const code = sessionStorage.getItem("code")
    const gtin = sessionStorage.getItem("gtin")
    const productCode = sessionStorage.getItem("productCode")
    if (searchReferrer) {
      if (
        productCode == null &&
        searchReferrer &&
        searchReferrer["gtin"] &&
        searchReferrer["code"]
      ) {
        setProductCode(
          `(01)${searchReferrer["gtin"]}(21)${searchReferrer["code"]}`
        )
        sessionStorage.setItem(
          "productCode",
          `(01)${searchReferrer["gtin"]}(21)${searchReferrer["code"]}`
        )
      } else {
        setProductCode(productCode)
      }
      // @ts-ignore
    } else if (search !== "" || path !== "/" || (code && gtin) || productCode) {
      if (
        productCode == null &&
        !code &&
        search &&
        search["gtin"] &&
        search["code"]
      ) {
        setProductCode(`(01)${search["gtin"]}(21)${search["code"]}`)
        sessionStorage.setItem(
          "productCode",
          `(01)${search["gtin"]}(21)${search["code"]}`
        )
      }
      if (code && gtin) {
        setProductCode(`(01)${gtin}(21)${code}`)
        sessionStorage.setItem("productCode", `(01)${gtin}(21)${code}`)

        sessionStorage.removeItem("code")
        sessionStorage.removeItem("gtin")
      }
      if (productCode) {
        setProductCode(productCode)
      }
      const codeArray = path.split("/")
      let stringBuilderProduct = ""

      if (codeArray?.length > 0) {
        if (!isUndefined(search.uid)) {
          const searchParameterGTIN = extractCode(search.uid)

          // check for old URL format
          if (searchParameterGTIN) {
            sessionStorage.setItem("productCode", searchParameterGTIN)

            const geoLocation = search.geo?.split("%2C")
            if (geoLocation) {
              setLatitude(
                isNumeric(geoLocation[0]) ? parseFloat(geoLocation[0]) : null
              )
              setLongitude(
                isNumeric(geoLocation[1]) ? parseFloat(geoLocation[1]) : null
              )
              setGeoLocationStatus("TRACKED")
            }

            !isUndefined(search.edp)
              ? setEndpoint(search.edp)
              : setEndpoint(globalEndpoint)
          }
        } else {
          // check for digital link format
          for (let i = 0; i < codeArray.length; i++) {
            if (codeArray[i] === "") continue

            stringBuilderProduct = stringBuilderProduct.concat(
              i % 2 === 0 ? codeArray[i] : `(${codeArray[i]})`
            )
          }

          for (const ai in search) {
            stringBuilderProduct = stringBuilderProduct.concat(
              `(${ai})${search[ai]}`
            )
          }

          if (stringBuilderProduct !== "") {
            sessionStorage.setItem("productCode", stringBuilderProduct)
          }

          if (!checkGTIN(stringBuilderProduct)) {
            setGeoLocationStatus("TRACKED")
          }
        }
      }
    }
    return () => {
      sessionStorage.removeItem("productCode")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, search])

  useEffect(() => {
    if (
      !(geoLocationStatus === "TRACKED") &&
      productCode !== null &&
      !search.geo
    ) {
      getGeoLocation()
        .then((geoLocation) => {
          const [latitude, longitude] = geoLocation
          setLatitude(latitude)
          setLongitude(longitude)
          setGeoLocationStatus("TRACKED")
        })
        .catch((err) => {
          console.error("Location failed: " + err)
          setGeoLocationStatus("DENIED")
        })
    }
  }, [productCode, geoLocationStatus, search.geo])

  useEffect(() => {
    if (latitude !== null && longitude !== null) {
      setGeoLocationStatus("TRACKED")
    }
  }, [latitude, longitude, geoLocationStatus])

  useEffect(() => {
    if (
      !loading.current &&
      (geoLocationStatus === "TRACKED" || geoLocationStatus === "DENIED")
    ) {
      loading.current = true
      authenticityCheckStore
        .fetchAuthenticityCheckList(
          productCode,
          lang,
          latitude,
          longitude,
          endpoint
        )
        .then(() => {
          window.history.replaceState(null, "", "/#/authenticity")
        })
        .catch((e) => {
          loading.current = false
          console.error(e)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCode, lang, latitude, longitude, geoLocationStatus])

  const authenticityResult = React.useMemo(() => {
    return authenticityCheckList
  }, [authenticityCheckList])

  const isMobile = window.innerWidth < theme.tokens.breakpoints.breakpointTablet

  return (
    <ResponsiveBackground theme={theme}>
      <Container
        sx={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        {loadingAuthenticityCheckList ||
        (geoLocationStatus === "INIT" && productCode) ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="10vh"
            sx={{ flexDirection: "column" }}
          >
            <CircularProgress color={"primary"} size="5vh" />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flexStart",
                padding: "0px 0px 0px 0px",
              }}
            >
              <Box sx={{ margin: "20px 0px" }}>
                <BlurredBackground>
                  <OverlayContent>
                    <Typography
                      fontWeight={
                        window.innerWidth >
                        theme.tokens.breakpoints.breakpointTablet
                          ? 700
                          : 600
                      }
                      color={theme.tokens.color.conti_typo}
                      marginBottom="10px"
                      marginTop="10px"
                      sx={{
                        fontSize:
                          window.innerWidth <
                          theme.tokens.breakpoints.breakpointTablet
                            ? "17px"
                            : isAte
                            ? "2.3rem"
                            : "26px",
                        lineHeight: "35px",
                        fontFamily: isAte
                          ? "Etelka Light"
                          : "ContinentalStagSans-Light",
                        textShadow:
                          "2px 2px 20px rgba(0, 0, 0, 2), -2px -2px 20px rgba(0, 0, 0, 2)",
                      }}
                    >
                      {t("screenText2")}
                    </Typography>
                    <ContiSpecificButton
                      path={"/scan"}
                      text={t("textGo")}
                      isForwardIcon={true}
                      width="100px"
                    />
                  </OverlayContent>
                </BlurredBackground>
              </Box>
              <Typography
                sx={{
                  fontSize: theme.tokens.fonts_Conti.fontSize_C_H,
                  fontWeight: theme.tokens.fonts_Conti.fontWeight_heavy,
                  lineHeight: "33.3px",
                }}
              >
                {t("resultText")}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flexStart",
                  paddingTop: theme.spacing(2),
                }}
              >
                <Box
                  sx={{
                    width: isMobile ? "30%" : "15%",
                    marginTop: theme.spacing(1),
                  }}
                >
                  <CheckResultFlags flag={authenticityResult?.codeFlag} />
                </Box>
                <Box sx={{ width: "90%" }}>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "300",
                      lineHeight: "33.3px",
                    }}
                  >
                    {authenticityResult?.responseText}
                  </Typography>
                </Box>
              </Box>
              <Box paddingTop="20px">
                <Box sx={{ margin: "20px 0px" }}>
                  <ContiSpecificButton
                    path={
                      i18n.language in links
                        ? links[i18n.language]
                        : links["en"]
                    }
                    text={t("buttonTextReport")}
                    hidden={
                      authenticityResult?.codeFlag === "GREEN" ||
                      authenticityResult?.codeFlag === ""
                    }
                    isForwardIcon={true}
                  ></ContiSpecificButton>
                </Box>
                <Typography
                  sx={{
                    fontSize: theme.tokens.fonts_Conti.fontSize_C_H,
                    fontWeight: theme.tokens.fonts_Conti.fontWeight_heavy,
                    lineHeight: "33.3px",
                  }}
                >
                  {t("mappCodeTitle")}
                </Typography>

                <Box sx={{ width: "100", marginBottom: "20px" }}>
                  <Chip
                    sx={{
                      height: "auto",
                      "& .MuiChip-label": {
                        display: "block",
                        whiteSpace: "normal",
                        width: isAte ? "31rem !important" : "18rem",
                      },
                    }}
                    label={
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontFamily: isAte
                            ? "Etelka Light"
                            : "ContinentalStagSans-Light",
                          fontWeight: theme.tokens.fonts_Conti.fontWeight,
                          lineHeight: "1.5",
                          letterSpacing: "3px",
                        }}
                      >
                        {authenticityResult?.code.replace(/\(21\)/g, "\n(21)")}
                      </Typography>
                    }
                  />
                </Box>
                <Box sx={{ padding: "25px 0px" }}>
                  {authenticityResult?.codeFlag === "RED" && (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        sx={{
                          fontSize: theme.tokens.fonts_Conti.fontSize_C_H,
                          fontWeight: theme.tokens.fonts_Conti.fontWeight_heavy,
                        }}
                      >
                        {t("entryCorrect")}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: theme.tokens.fonts_Conti.fontSize_C_H,
                          fontWeight: theme.tokens.fonts_Conti.fontWeight,
                          lineHeight: "33.3px",
                          marginBottom: "10px",
                        }}
                      >
                        {t("contactText")}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Container>
    </ResponsiveBackground>
  )
}

export default observer(AuthenticityCheck)
