import { ERoute, TRoute } from "types/Global"
import AuthenticityCheck from "./AuthenticityCheck"
import SecondScan from "./SecondScan/SecondScan"
import StartScreen from "./StartScreen"
import MAPPCodeScreen from "./MAPPCodeScreen"
import TecDoc from "./TecDoc/TecDoc"

export const routes: TRoute[] = [
  {
    path: ERoute.ROOT,
    Component: StartScreen,
  },
  {
    path: ERoute.MAPPCodeScreen,
    Component: MAPPCodeScreen,
  },
  {
    path: ERoute.AUTHENTICITY_CHECK,
    Component: AuthenticityCheck,
  },
  {
    path: ERoute.SECONDSCAN,
    Component: SecondScan,
  },
  {
    path: ERoute.TECDOC,
    Component: TecDoc,
  },
]
