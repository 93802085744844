import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined"
import { AppBar, Box, Divider, Toolbar, Typography } from "@mui/material"
import React from "react"
import { useHistory } from "react-router-dom"
import { useTecDocStore } from "../../api/services/TecDoc"
import { TopBarProps } from "./types"
import { isAte } from "utils/Global"
import { scrolltToTop } from "utils/util"

const TopBar: React.FC<TopBarProps> = (props) => {
  const [topMargin, setTopMargin] = React.useState(0)

  const { title } = props
  const history = useHistory()

  const tecDocStore = useTecDocStore()

  const handleOnClick = () => {
    const { pathname } = history.location

    switch (pathname) {
      case "/scan":
        history.push("/")
        break
      case "/tecdoc":
        history.goBack()
        tecDocStore.reset()
        break
      case "/authenticity":
        history.push("/mappcodescreen")
        break
      default:
        history.goBack()
        break
    }
    scrolltToTop()
  }

  React.useEffect(() => {
    const mainNav = document.querySelector(".main-navigation") as HTMLElement
    const metaNav = document.querySelector(".meta-navigation") as HTMLElement
    const ateMetaNav = document.getElementById("meta-bar") as HTMLElement
    const ateLogoMetaNavBar = document.getElementById("nav-bar") as HTMLElement

    const ateLogoMetaNav = document.getElementById("logo") as HTMLElement

    if (mainNav && metaNav) {
      const mainNavHeight = mainNav.offsetHeight
      const metaNavHeight = metaNav.offsetHeight
      setTopMargin(mainNavHeight + metaNavHeight)
    }
    if (isAte && ateMetaNav) {
      const ateMainNavheight = ateMetaNav.offsetHeight
      const top = ateMetaNav.getBoundingClientRect().top
      if (ateLogoMetaNavBar) {
        const logoHeight = ateLogoMetaNavBar.offsetHeight
        const top = ateLogoMetaNavBar.getBoundingClientRect().top
        setTopMargin(logoHeight + top)
      } else if (ateLogoMetaNav) {
        const logoHeight = ateLogoMetaNav.offsetHeight
        if (logoHeight > ateMetaNav.offsetHeight) {
          setTopMargin(logoHeight + top)
        }
      } else {
        setTopMargin(ateMainNavheight + top)
      }
    }
  }, [])

  const validPaths = [
    "/authenticity",
    "/scan",
    "/tecdoc",
    "/mappcodescreen",
    "/report",
  ]

  return (
    <AppBar
      id="os-title-bar"
      position="sticky"
      sx={{
        backgroundColor: "white !important",
        background: "white !important",
        height: "77px",
        top: `${topMargin}px`,
        width: "100%",
        zIndex: "10 !important",
      }}
    >
      <Toolbar sx={{ paddingTop: "40px", paddingBottom: "40px" }}>
        {validPaths.includes(history.location.pathname) && (
          <>
            <ArrowBackOutlinedIcon
              onClick={handleOnClick}
              sx={{ cursor: "pointer", marginRight: "15px", color: "black" }}
            />
            <Divider
              orientation="vertical"
              sx={{ height: "76px", width: "3px" }}
            />
          </>
        )}
        {validPaths.includes(history.location.pathname) && (
          <Box width="40px"></Box>
        )}
        <Typography color={"black"}>{title}</Typography>
      </Toolbar>
    </AppBar>
  )
}
export default TopBar
