import { merge } from "lodash"

import { createTheme } from "@mui/material/styles"

import tokens, { fonts, OsapiensThemeTokens } from "./tokens"
import { isAte } from "utils/Global"

declare module "@mui/material/styles" {
  interface Theme {
    tokens: OsapiensThemeTokens
  }
  interface BreakpointOverrides {
    xs: true
    sm: true
    md: true
    lg: true
    xl: true
    mobile: true
    tablet: true
    laptop: true
    desktop: true
  }
}

const commonTheme = {
  palette: {
    mode: "light",
    primary: {
      main: tokens.color.primary,
    },
    secondary: {
      main: tokens.color.secondary,
    },
    success: {
      main: tokens.color.success,
    },
    warning: {
      main: tokens.color.warning,
    },
    error: {
      main: tokens.color.error,
    },
    grey: {
      main: tokens.color.grey,
    },
    info: {
      main: tokens.color.info,
    },
    link: {
      main: tokens.color.link,
    },
    contrastThreshold: 3,
    tonalOffset: {
      light: 0.2,
      dark: 0.2,
    },
  },
  shape: {
    borderRadius: tokens.style.radius,
  },
  breakpoints: {
    values: {
      xs: tokens.breakpoints.breakpointXs,
      sm: tokens.breakpoints.breakpointSm,
      md: tokens.breakpoints.breakpointMd,
      lg: tokens.breakpoints.breakpointLg,
      xl: tokens.breakpoints.breakpointXl,
      mobile: tokens.breakpoints.breakpointMobile,
      tablet: tokens.breakpoints.breakpointTablet,
      laptop: tokens.breakpoints.breakpointLaptop,
      desktop: tokens.breakpoints.breakpointDesktop,
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": fonts,
      },
    },
  },
}

const muiBaseTheme = createTheme()

const tokensLight: any = merge({}, commonTheme, {
  palette: {
    mode: "light",
  },
  typography: {
    allVariants: {
      fontFamily: isAte ? "Etelka Light !important" : "",
      textTransform: "none",
      fontSize: 16,
    },
  },
  tokens,
})

const tokensDark: any = merge({}, commonTheme, {
  palette: {
    mode: "dark",
    background: {
      default: muiBaseTheme.palette.common.black,
      paper: muiBaseTheme.palette.grey["900"],
    },
  },
  typography: {
    allVariants: {
      fontFamily: isAte ? "Etelka Light !important" : "",
      textTransform: "none",
      fontSize: 16,
    },
  },
  tokens,
})

const osapiensTheme = {
  light: createTheme(tokensLight),
  dark: createTheme(tokensDark),
}

export default osapiensTheme
