import { makeAutoObservable, runInAction } from "mobx"
import {
  ActionError,
  ActionResultStatus,
  ActionSuccess,
  QueryResponse,
} from "types/Global"
import { resultOrError } from "utils/Global"
import { AuthenticityCheck } from "./types"
import { call } from "../../CloudAPI"
import { checkGTIN } from "pages/AuthenticityCheck/util"

class AuthenticityCheckStore {
  // lists
  loadingAuthenticityCheckList = false
  authenticityCheckList: AuthenticityCheck = {
    code: "",
    codeFlag: "",
    logo: "",
    organization: "",
    promotional: {
      buttonText: "",
      url: "",
    },
    responseText: "",
    validation: "",
    vas: [],
  }

  // init function
  constructor() {
    makeAutoObservable(this)
  }

  // actions
  async fetchAuthenticityCheckList(
    serialNumber: string,
    language: string,
    latitude: number,
    longitude: number,
    endpoint: string
  ) {
    if (checkGTIN(serialNumber)) {
      this.loadingAuthenticityCheckList = true

      const body = {
        serialNumber,
        origin: "1D+ Mobile Response Page (Scan)",
        endpoint: endpoint,
        user: "anonymous@1idplus.com",
        language,
        latitude,
        longitude,
      }

      const [result, error]: any = await resultOrError<QueryResponse<any>>(
        call(
          "ValidateSerialnumberMaster",
          {
            ...body,
          },
          "BrandOSMaster"
        )
      )

      if (result) {
        runInAction(() => {
          this.authenticityCheckList = result[serialNumber]
          this.loadingAuthenticityCheckList = false
        })
        return {
          status: ActionResultStatus.SUCCESS,
          result: result[serialNumber],
        } as ActionSuccess<number>
      } else {
        runInAction(() => {
          this.loadingAuthenticityCheckList = false
        })
        return {
          status: ActionResultStatus.ERROR,
          error: error,
        } as ActionError
      }
    } else if (serialNumber !== null) {
      runInAction(() => {
        this.authenticityCheckList = {
          code: "",
          codeFlag: "RED",
          logo: "",
          organization: "osapiens 1D+",
          promotional: { buttonText: "", url: "" },
          responseText:
            'This product seems to be suspicious. Please contact the manufacturer using the "Report Counterfeit" function and ask for further authentication.',
          validation: "",
          vas: null,
        }
        if (language.toLowerCase() === "de") {
          this.authenticityCheckList.responseText =
            "Dieses Produkt erscheint verdächtig. Bitte kontaktieren Sie den Hersteller mit der “Fälschung melden“ Funktion und fordern Sie eine zusätzliche Authentifizierung an."
        }
        if (language.toLowerCase() === "es") {
          this.authenticityCheckList.responseText =
            "Este producto parece sospechoso. Por favor, póngase en contacto con el fabricante mediante la función “Report Counterfeit“ y solicite una autenticación adicional."
        }
        if (language.toLowerCase() === "fr") {
          this.authenticityCheckList.responseText =
            "Ce produit semble suspect. Veuillez contacter le fabricant en utilisant la fonction «Signaler un faux ou une contrefaçon» et demander une authentification supplémentaire."
        }
        if (language.toLowerCase() === "fr") {
          this.authenticityCheckList.responseText =
            "Ce produit semble suspect. Veuillez contacter le fabricant en utilisant la fonction «Signaler un faux ou une contrefaçon» et demander une authentification supplémentaire."
        }
        if (language.toLowerCase() === "it") {
          this.authenticityCheckList.responseText =
            "Questo prodotto sembra essere sospetto. Si prega di contattare il produttore utilizzando la funzione “Segnala contraffazione“ e di richiedere un'ulteriore autenticazione."
        }
      })
    }
  }
}

export default AuthenticityCheckStore
