import { Box } from "@mui/material"
import React from "react"
import { observer } from "mobx-react"
import { StyledTabPanel } from "./styles"

const TabPanel = (props) => {
  const { children, value, index, ...other } = props

  return (
    <StyledTabPanel
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
      hasPadding={props.hasPadding}
    >
      {value === index && <Box>{children}</Box>}
    </StyledTabPanel>
  )
}

export default observer(TabPanel)
