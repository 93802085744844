import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"

const StyledListItem = styled(Box)<{
  theme?: any
  borderBottom?: string
}>`
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: ${(props) => props.theme.spacing(1)}
    ${(props) => props.theme.spacing(2)} ${(props) => props.theme.spacing(1)}
    ${(props) => props.theme.spacing(2)};
  border-bottom: ${(props) => props.borderBottom};
`

const StyledSubListItem = styled(Box)<{
  gap?: string
  justifyContent: string
}>`
  display: flex;
  flex-direction: row;
  gap: ${(props) => props.gap};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "initial"};
`

const StyledListHeading = styled(Box)<{
  theme: any
  background: string
}>`
  display: flex;
  align-items: center;
  justify-content: left;
  padding: ${(props) => props.theme.spacing(1)}
    ${(props) => props.theme.spacing(2)} ${(props) => props.theme.spacing(1)}
    ${(props) => props.theme.spacing(2)};
  background: ${(props) => props.background};
  color: white;
  border-radius: ${(props) => props.theme.spacing(0.8)};
`

export { StyledListItem, StyledSubListItem, StyledListHeading }
