import { isAte } from "utils/Global"
import "../../App.css"
export interface OsapiensColors {
  primary: string
  secondary: string
  success: string
  warning: string
  error: string
  info: string
  grey: string
  link: string
  lighten0: string
  lighten1: string
  lighten2: string
  lighten3: string
  lighten4: string
  lighten5: string
  lighten6: string
  darken0: string
  darken1: string
  darken2: string
  darken3: string
  darken4: string
  darken5: string
  darken6: string
  conti_yellow: string
  conti_typo: string
  conti_typo_button: string
  ate_blue: string
}

export interface OsapiensFont {
  fontFamily: string
  fontStyle: string
  fontDisplay: string
  fontWeight: number
  src: string
}
export interface ContiFont {
  fontFamily: string
  fontStyle: string
  fontDisplay: string
  fontWeight: number
  fontWeight_heavy: string
  src: string
  fontSize_C_H: string
}

export interface OsapiensThemeTokens {
  style: {
    radius: number
  }
  color: OsapiensColors
  breakpoints: {
    breakpointXs: number
    breakpointSm: number
    breakpointMd: number
    breakpointLg: number
    breakpointXl: number
    breakpointMobile: number
    breakpointTablet: number
    breakpointLaptop: number
    breakpointDesktop: number
  }
  fonts: OsapiensFont[]
  fonts_Conti: ContiFont
  images: {
    background: string
  }
  tabbedHeader: {
    height: string
  }
  header: {
    height: string
  }
}

export const colors: OsapiensColors = {
  primary: !isAte ? "#FEA500" : "#005CA9",
  secondary: !isAte ? "#FEA500" : "#005CA9",
  success: "#90c62c",
  warning: "#eebf00",
  error: "#d55342",
  info: "#e0e0e0",
  grey: "rgba(51,51,51,0.7)",
  link: "#039be5",
  lighten0: "rgba(255, 255, 255, 0.03)",
  lighten1: "rgba(255, 255, 255, 0.05)",
  lighten2: "rgba(255, 255, 255, 0.12)",
  lighten3: "rgba(255, 255, 255, 0.24)",
  lighten4: "rgba(255, 255, 255, 0.38)",
  lighten5: "rgba(255, 255, 255, 0.5)",
  lighten6: "rgba(255, 255, 255, 0.85)",
  darken0: "rgba(0, 0, 0, 0.03)",
  darken1: "rgba(0, 0, 0, 0.07)",
  darken2: "rgba(0, 0, 0, 0.12)",
  darken3: "rgba(0, 0, 0, 0.24)",
  darken4: "rgba(0, 0, 0, 0.38)",
  darken5: "rgba(0, 0, 0, 0.5)",
  darken6: "rgba(0, 0, 0, 0.85)",
  conti_yellow: "#FEA500",
  conti_typo: "#FFFFFF",
  conti_typo_button: "#000000",
  ate_blue: "#005CA9",
}

export const fonts: OsapiensFont[] = [
  {
    fontFamily: "FreeMono, monospace",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 400,
    // eslint-disable-next-line
    src: `url(${require("!!url-loader!@mdi/font/fonts/materialdesignicons-webfont.woff2")}) format('woff2')`,
  },
]
export const fonts_Conti: ContiFont = {
  fontFamily: "FreeMono, monospace",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  fontWeight_heavy: "600",
  fontSize_C_H: "18px",
  // eslint-disable-next-line
  src: `url(${require("!!url-loader!@mdi/font/fonts/materialdesignicons-webfont.woff2")}) format('woff2')`,
}

export const tokens: OsapiensThemeTokens = {
  color: colors,
  style: {
    radius: 4,
  },
  breakpoints: {
    breakpointXs: 0,
    breakpointSm: 600,
    breakpointMd: 960,
    breakpointLg: 1280,
    breakpointXl: 1920,
    breakpointMobile: 340,
    breakpointTablet: 640,
    breakpointLaptop: 800,
    breakpointDesktop: 1024,
  },
  fonts,
  fonts_Conti: fonts_Conti,
  images: {
    background: "transparent", // require("!!url-loader!./assets/background-pattern.svg"),
  },
  tabbedHeader: {
    height: "80px",
  },
  header: {
    height: "0px",
  },
}

export default tokens
