import { Box } from "@mui/material"
import React from "react"

import Icon from "@mdi/react"
import { mdiChevronRight } from "@mdi/js"

import { StyledIcon, StyledTabVertical, CenteredChevron } from "./styles"

const VerticalTab = (props) => {
  const { icon, label, ...other } = props

  return (
    <StyledTabVertical
      {...(icon
        ? {
            icon: (
              <StyledIcon color={icon.background}>
                <Icon path={icon ? icon.path : null} />
              </StyledIcon>
            ),
          }
        : {})}
      {...(icon ? { iconPosition: "start" } : {})}
      label={
        <Box sx={{ display: "inline-flex" }}>
          <Box sx={{ textAlign: "left", paddingLeft: "10px" }}></Box>
          <CenteredChevron path={mdiChevronRight} />
        </Box>
      }
      {...other}
    />
  )
}

export default VerticalTab
