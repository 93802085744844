import * as React from "react"
import { observer } from "mobx-react"

import { Box, useTheme } from "@mui/material"

import useScopedTranslation from "hooks/useScopedTranslation"
import en from "./locales/en.json"
import { useTecDocStore } from "../../../../api/services/TecDoc"
import { useEffect, useMemo } from "react"
import i18next from "i18next"
import List, { ListTextItemTypes } from "pages/TecDoc/components/List"
import {
  createItemBorder,
  getDate,
  getDateSingle,
  groupListByValue,
} from "pages/util"
import ListHeading from "pages/TecDoc/components/List/components/ListHeading"
export const namespace = __filename

const Vehicles: React.FC<{ handleLoading: (value: boolean) => void }> = (
  props
) => {
  const theme = useTheme()
  const lang = i18next.language
  const countryCatalogue = localStorage.getItem("countryCatalogueCode") || "de"

  const { t } = useScopedTranslation(
    namespace,
    (locale) => import(`./locales/${locale}.json`),
    en
  )
  const tecDocStore = useTecDocStore()
  const {
    vehicleList,
    vehicleListImmediateAttributs,
    articleList,
    loadingVehicleList,
    loadingVehicleImmediateAttributs,
  } = tecDocStore

  useEffect(() => {
    props.handleLoading(loadingVehicleImmediateAttributs || loadingVehicleList)
  }, [loadingVehicleList, loadingVehicleImmediateAttributs, props])

  const vehicleLinkages = useMemo(() => {
    let tempArticleAttributes: ListTextItemTypes[] = []

    if (vehicleListImmediateAttributs) {
      tempArticleAttributes = Object.entries(vehicleListImmediateAttributs).map(
        (item) => {
          const linkedVehicles = item[1]?.linkedVehicles.array[0]
          const linkedArticleImmediateAttributs =
            item[1]?.linkedArticleImmediateAttributs.array

          const vehicleLabel = [
            {
              label: String(
                `${linkedVehicles?.modelDesc} ${linkedVehicles?.carDesc}`
              ),
              hasBoldLabel: true,
              autoWidth: true,
            },
          ]

          const immadiateAttributsLabel = linkedArticleImmediateAttributs
            ? Object.values(linkedArticleImmediateAttributs).map((item) => {
                return {
                  label: String(`${item?.attrName}: `),
                  content: String(
                    item?.attrId === 20 || item?.attrId === 21
                      ? getDateSingle(item?.attrValue)
                      : item?.attrValue
                  ),
                  hasBoldLabel: false,
                }
              })
            : []

          const vehicleDetails = [
            {
              label: `${t("type")}:`,
              content: String(linkedVehicles?.constructionType),
            },
            {
              label: `${t("yearOfConstruction")}:`,
              content: String(
                getDate(
                  linkedVehicles?.yearOfConstructionFrom,
                  linkedVehicles?.yearOfConstructionTo
                )
              ),
            },
            {
              label: `${t("power")}:`,
              content: `${linkedVehicles?.powerHpTo} ${t("units.hp")}/${
                linkedVehicles?.powerKwTo
              } ${t("units.kw")}`,
            },
            {
              label: `${t("cylinderCapacity")}:`,
              content: `${linkedVehicles?.cylinderCapacity} ${t("units.ccm")}`,
            },
          ]

          const seperator = [
            {
              label: "separator",
              separator: true,
            },
          ]

          return {
            key: String(linkedVehicles?.carId),
            brand: linkedVehicles?.manuDesc,
            subItems: [
              ...vehicleLabel,
              ...vehicleDetails,
              ...seperator,
              ...immadiateAttributsLabel,
            ],
          }
        }
      )
    }
    return Object.entries(groupListByValue(tempArticleAttributes, "brand")).map(
      (item) => {
        return (
          <List
            type={"TEXT"}
            key={item[0]}
            title={item[0]}
            items={createItemBorder(item[1])}
            accordion={true}
          />
        )
      }
    )
  }, [vehicleListImmediateAttributs, t])

  useEffect(() => {
    if (articleList?.articleId && vehicleLinkages.length === 0) {
      tecDocStore
        .fetchVehicleList(countryCatalogue, lang, articleList.articleId)
        .catch((err) => console.error("Error fetching vehicles", err))
    }
  }, [tecDocStore, articleList, vehicleLinkages, countryCatalogue, lang])

  useEffect(() => {
    if (vehicleList && vehicleLinkages.length === 0) {
      const linkedArticlePairs: {
        articleLinkId: number
        linkingTargetId: number
      }[] = vehicleList.map((item) => {
        return {
          articleLinkId: item.articleLinkId,
          linkingTargetId: item.linkingTargetId,
        }
      })

      tecDocStore
        .fetchVehicleListImmediateAttributs(
          countryCatalogue,
          lang,
          linkedArticlePairs,
          articleList.articleId
        )
        .catch((err) => console.error("Error fetching vehicle details", err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tecDocStore, vehicleList, vehicleLinkages, countryCatalogue, lang])

  return (
    <Box sx={{ paddingBottom: theme.spacing(4) }}>
      <Box sx={{ paddingBlock: theme.spacing(2) }}>
        {!(
          loadingVehicleImmediateAttributs ||
          loadingVehicleList ||
          vehicleList === null
        ) && (
          <ListHeading
            title={`${t("linkedVehicles")}`}
            caret={"NONE"}
            variant={"PRIMARY"}
          />
        )}
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(1) }}
      >
        {vehicleLinkages}
      </Box>
    </Box>
  )
}

export default observer(Vehicles)
