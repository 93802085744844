import { styled } from "@mui/material/styles"
import Box, { BoxProps } from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import Icon from "@mdi/react"
import { convertSizeRem } from "utils/util"

const StyledTabHorizontal = styled(Tab)(({ theme }) => ({
  ...theme.mixins.toolbar,
}))

const StyledTabPanel = styled(Box)<BoxProps & { hasPadding: boolean }>(
  ({ theme, hasPadding }) => {
    return {
      padding: hasPadding ? theme.spacing(4) : theme.spacing(0),
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        padding: hasPadding ? theme.spacing(2) : theme.spacing(0),
      },
    }
  }
)

const StyledTabVertical = styled(Tab)(({ theme }) => ({
  paddingBlock: theme.spacing(2.5),
  paddingInline: theme.spacing(2.5),
  borderBottom: `1px solid ${theme.palette.divider}`,
  justifyContent: "left",
  maxWidth: "100%",
  "&.Mui-selected": {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.grey[300],
  },
  [theme.breakpoints.down("md")]: {
    paddingInline: theme.spacing(1.25),
    paddingBlock: theme.spacing(2.5),
  },
}))

const StyledIcon = styled(Box)(({ theme, color }) => ({
  color: color.toString(),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: 0,
  "& svg": {
    width: convertSizeRem(3),
    height: convertSizeRem(3),
  },
  [theme.breakpoints.down("md")]: {
    minWidth: 0,
    width: "2.5rem",
    height: "2.5rem",
    "& svg": {
      width: convertSizeRem(2),
      height: convertSizeRem(2),
    },
  },
}))

const CenteredChevron = styled(Icon)(({ theme }) => ({
  width: "3rem",
  height: "3rem",
  position: "absolute",
  right: theme.spacing(1.5),
  top: "50%",
  transform: "translateY(-50%)",
  [theme.breakpoints.down("lg")]: {
    width: "2.5rem",
    height: "2.5rem",
  },
  [theme.breakpoints.down("md")]: {
    width: "2rem",
    height: "2rem",
  },
}))

export {
  StyledIcon,
  StyledTabHorizontal,
  StyledTabVertical,
  StyledTabPanel,
  CenteredChevron,
}
