import i18next from "i18next"
import React from "react"
import { useTranslation } from "react-i18next"

const useScopedTranslation = (
  namespace: string,
  lazyLoadLocales: (locale) => Promise<any>,
  defaultTranslations: any,
  defaultLanguage: string = "en"
) => {
  const { t, i18n } = useTranslation(namespace)
  // load new translations on language switch
  React.useEffect(() => {
    // load default language directly
    if (!i18next.hasResourceBundle(defaultLanguage, namespace)) {
      i18next.addResourceBundle(defaultLanguage, namespace, defaultTranslations)
    }
    // lazy load foreign language on demand
    if (!i18next.hasResourceBundle(i18n.language, namespace)) {
      lazyLoadLocales(i18n.language).then((translations) => {
        i18next.addResourceBundle(i18n.language, namespace, translations)
      })
    }
  }, [
    t,
    i18n.language,
    defaultLanguage,
    namespace,
    defaultTranslations,
    lazyLoadLocales,
  ])

  return { t, i18n }
}

export default useScopedTranslation
