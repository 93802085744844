import * as React from "react"
import { observer } from "mobx-react"

import { Box, useTheme } from "@mui/material"
import { useTecDocStore } from "api/services/TecDoc"
import { useMemo } from "react"
import useScopedTranslation from "../../../../hooks/useScopedTranslation"
import en from "./locales/en.json"
import List, { ListTextItemTypes } from "pages/TecDoc/components/List"
import { createItemBorder, groupListByValue } from "pages/util"
import ListHeading from "pages/TecDoc/components/List/components/ListHeading"

export const namespace = __filename

const OE: React.FC = () => {
  const tecDocStore = useTecDocStore()
  const { oeNumber } = tecDocStore
  const theme = useTheme()
  const { t } = useScopedTranslation(
    namespace,
    (locale) => import(`./locales/${locale}.json`),
    en
  )

  const oeLinkages = useMemo(() => {
    let tempOeLinkages: ListTextItemTypes[] = []
    if (oeNumber) {
      tempOeLinkages = oeNumber.map((item) => {
        return {
          brand: item.brandName,
          key: item.oeNumber,
          subItems: [
            {
              label: item.oeNumber,
              content: "",
            },
          ],
        }
      })
    }

    return Object.entries(groupListByValue(tempOeLinkages, "brand")).map(
      (item) => {
        return (
          <List
            type={"TEXT"}
            key={item[0]}
            title={item[0]}
            items={createItemBorder(item[1])}
          />
        )
      }
    )
  }, [oeNumber])

  return (
    <Box>
      <Box sx={{ paddingBlock: theme.spacing(2), color: "white" }}>
        <ListHeading title={t("oe")} caret={"NONE"} variant={"PRIMARY"} />
      </Box>
      <Box>{oeLinkages}</Box>
    </Box>
  )
}

export default observer(OE)
