import { styled } from "@mui/material/styles"
import { Box } from "@mui/material"

export const StyledBackground = styled(Box)(({ theme }) => ({
  paddingBlock: theme.spacing(2),
  [theme.breakpoints.down("md")]: {},
}))

// eslint-disable-next-line no-empty-pattern
export const StyledContent = styled(Box)(({}) => ({
  maxWidth: "100%",
  display: "flex",
  flexDirection: "column",
  marginTop: "15px",
}))
