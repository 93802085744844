import React from "react"

import { StyledIcon, StyledTabHorizontal } from "./styles"
import { SvgIcon, useTheme } from "@mui/material"

const HorizontalTab = (props) => {
  const { icon, label, selected, ...other } = props
  const theme = useTheme()

  return (
    <StyledTabHorizontal
      {...(icon
        ? {
            icon: (
              <StyledIcon
                color={
                  selected
                    ? theme.tokens.color.primary
                    : theme.tokens.color.grey
                }
              >
                <SvgIcon>
                  <path d={icon ? icon.path : null} />
                  {icon.text && (
                    <>
                      <circle cx="77%" cy="81%" r="6.5" fill="white" />
                      <text
                        x="55%"
                        y="91%"
                        style={{ fontSize: 7, fontWeight: "bold" }}
                        fill={theme.palette.secondary.main}
                      >
                        {icon.text}
                      </text>
                    </>
                  )}
                </SvgIcon>
              </StyledIcon>
            ),
          }
        : {})}
      {...(icon ? { iconPosition: "start" } : {})}
      label={label}
      {...other}
    />
  )
}

export default HorizontalTab
