import * as React from "react"
import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogProps as MuiDialogProps,
} from "@mui/material"
import { StyledButton } from "./styles"

interface ActionStyle {
  label?: string
  color?: string
}
export interface DialogProps extends MuiDialogProps {
  content?: any
  open: boolean
  title?: string
  text?: string
  closeStyle?: ActionStyle
  confirmStyle?: ActionStyle
  focusOut?: boolean
  disabledClose?: boolean
  hideClose?: boolean
  disabledConfirm?: boolean
  disabledAccept?: boolean
  onClose: () => void
  onConfirm?: () => void
  onAccept?: () => void
}

const Dialog: React.FC<DialogProps> = (props) => {
  const {
    content,
    open,
    title,
    text,
    closeStyle,
    confirmStyle,
    disabledClose = false,
    hideClose = false,
    disabledConfirm = false,
    disabledAccept = false,
    onClose,
    onConfirm,
    focusOut = true,
    children,
    onAccept,
    ...other
  } = props

  const handleClose = () => {
    onClose()
  }

  const handleSave = () => {
    onConfirm()
    onClose()
  }
  const handleSaveAccept = () => {
    onAccept()
  }

  return (
    <MuiDialog
      open={open}
      onClose={focusOut ? handleClose : () => {}}
      {...other}
    >
      <DialogTitle>{content?.productInfo?.name || title}</DialogTitle>
      <DialogContent>
        {text && <DialogContentText>{text}</DialogContentText>}
        {children}
        {content}
      </DialogContent>
      <DialogActions>
        <StyledButton
          sx={(theme) => ({
            color: closeStyle?.color || theme.palette.text.secondary,
            visibility: hideClose ? "hidden" : "",
          })}
          onClick={handleClose}
          disabled={disabledClose}
          hidden={hideClose}
        >
          {closeStyle?.label || "Close"}
        </StyledButton>
        {onConfirm && (
          <StyledButton
            sx={(theme) => ({
              color: confirmStyle?.color || theme.palette.success.main,
            })}
            onClick={handleSave}
            disabled={disabledConfirm}
          >
            {confirmStyle?.label || "Ok"}
          </StyledButton>
        )}
        {onAccept && (
          <StyledButton
            sx={(theme) => ({
              color: confirmStyle?.color || theme.palette.success.main,
            })}
            onClick={handleSaveAccept}
            disabled={disabledAccept}
          >
            {confirmStyle?.label || "Confirm"}
          </StyledButton>
        )}
      </DialogActions>
    </MuiDialog>
  )
}

export default Dialog
