import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"

const PREFIX = "tabs"

const classes = {
  horizontal: `${PREFIX}-horizontal`,
  vertical: `${PREFIX}-vertical`,
}

const TabBox = styled(Box)(({ theme }) => ({
  [`&.${classes.vertical}`]: {
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down("md")]: {},
  },
  [`&.${classes.horizontal}`]: {
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
}))

export { classes, TabBox }
