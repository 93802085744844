export const calculateImageSize = (image) => {
  const _image = new Image()
  _image.src = image

  return new Promise((resolve, reject) => {
    _image.onload = function () {
      let imageSize
      const ratio = this.width / this.height

      if (ratio >= 4 || ratio <= 0.25) {
        imageSize = 55 + "%"
      } else if (ratio >= 2 || ratio <= 0.5) {
        imageSize = 50 + "%"
      } else {
        imageSize = 30 + "%"
      }
      resolve(imageSize)
    }
  })
}
