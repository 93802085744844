import { styled } from "@mui/material/styles"
import { Box } from "@mui/material"

export const StyledImage = styled("img")(() => ({}))

export const StyledBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center" /* align horizontal */,
  alignItems: "center" /* align vertical */,
}))
