import * as React from "react"
import { useEffect, useMemo } from "react"
import { observer } from "mobx-react"

import { Box, useTheme } from "@mui/material"
import { useTecDocStore } from "api/services/TecDoc"
import en from "./locales/en.json"
import useScopedTranslation from "hooks/useScopedTranslation"
import i18next from "i18next"
import List, { ListTextItemTypes } from "pages/TecDoc/components/List"
import ListHeading from "pages/TecDoc/components/List/components/ListHeading"
import { createItemBorder } from "pages/util"

export const namespace = __filename

const Manufacturer: React.FC = () => {
  const lang = i18next.language
  const countryCatalogue = localStorage.getItem("countryCatalogueCode") || "de"
  const theme = useTheme()

  const tecDocStore = useTecDocStore()
  const { articleList, brandDetails } = tecDocStore
  const { t } = useScopedTranslation(
    namespace,
    (locale) => import(`./locales/${locale}.json`),
    en
  )

  useEffect(() => {
    if (!brandDetails) {
      tecDocStore
        .fetchBrandDetails(countryCatalogue, lang, articleList?.brandNo)
        .catch((err) => console.error("Error fetching brand details", err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tecDocStore, articleList])

  const brandItems = useMemo(() => {
    const requiredKeys = [
      "name2",
      "street",
      "city",
      "zip",
      "mailbox",
      "wwwURL",
      "phone",
      "fax",
      "email",
    ]
    let tempBrandDetails: ListTextItemTypes[] = []
    if (brandDetails) {
      tempBrandDetails = Object.entries(brandDetails).map((item) => {
        return {
          key: item[0],
          subItems: [
            {
              label: requiredKeys.includes(item[0]) ? t(item[0]) : item[0],
              content: item[1],
              docType: "",
            },
          ],
        }
      })
    }

    return tempBrandDetails
      .filter((item) => requiredKeys.includes(item?.key))
      .sort((a, b) =>
        requiredKeys.indexOf(a.key) > requiredKeys.indexOf(b.key) ? 1 : -1
      )
  }, [brandDetails, t])

  return (
    <Box>
      <Box sx={{ paddingBlock: theme.spacing(2), color: "white" }}>
        <ListHeading title={t("title")} caret={"NONE"} variant={"PRIMARY"} />
      </Box>
      <List
        type={"TEXT"}
        title={t("contact")}
        items={createItemBorder(brandItems)}
      />
    </Box>
  )
}

export default observer(Manufacturer)
