import Info from "./subpages/Info"
import {
  mdiCar,
  mdiEarth,
  mdiFileImage,
  mdiFormatListBulletedSquare,
  mdiInformation,
  mdiOfficeBuilding,
} from "@mdi/js"
import {
  Box,
  CircularProgress,
  Container,
  Typography,
  useTheme,
} from "@mui/material"
import Vehicles from "./subpages/Vehicles"
import Manufacturer from "./subpages/Manufacturer"
import Documents from "./subpages/Documents"
import OE from "./subpages/OE"
import React, { useEffect, useState } from "react"
import ResponsiveBackground from "../../components/ResponsiveBackground"
import { useTecDocStore } from "../../api/services/TecDoc"
import i18next from "i18next"
import useScopedTranslation from "../../hooks/useScopedTranslation"
import en from "./locales/en.json"
import tokens from "themes/default/tokens"
import Dialog from "components/Dialog"
import { useHistory } from "react-router"
import { uniq } from "lodash"
import { isAte } from "utils/Global"
import { scrolltToTop } from "utils/util"
import Tabs from "./components/Tabs"
import CountrySelection from "./components/CountrySelection"

export const namespace = __filename

const TecDoc = () => {
  const theme = useTheme()
  const history = useHistory()
  const lang = i18next.language
  const countryCatalogue = localStorage.getItem("countryCatalogueCode") || "de"
  const [isHomeSelected, setIsHomeSelected] = useState(false)
  const { t } = useScopedTranslation(
    namespace,
    (locale) => import(`./locales/${locale}.json`),
    en
  )

  const tecDocStore = useTecDocStore()
  const { articleList, articleAttributes } = tecDocStore

  const [loading, setLoading] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  useEffect(() => {
    scrolltToTop()
  }, [])

  const handleLoading = (value: boolean) => {
    setLoading(value)
  }
  useEffect(() => {
    if (isHomeSelected) setIsHomeSelected(false)
  }, [isHomeSelected])

  function backToHome() {
    setIsHomeSelected(true)
  }

  useEffect(() => {
    if (!articleList) {
      tecDocStore
        .fetchArticle(countryCatalogue, lang, 6, localStorage.getItem("data"))
        .catch((err) => {
          console.error("Error fetching articles", err)
          setIsDialogOpen(true)
        })
    }
    return () => {
      localStorage.removeItem("data")
    }
  }, [tecDocStore, countryCatalogue, lang, articleList])

  useEffect(() => {
    if (articleList && !articleAttributes) {
      tecDocStore
        .fetchArticleDetails(countryCatalogue, lang, articleList?.articleId)
        .catch((err) => {
          console.error("Error fetching articles", err)
          setIsDialogOpen(true)
        })
    }
  }, [tecDocStore, articleList, countryCatalogue, lang, articleAttributes])

  const generalArticleNumber = React.useMemo(() => {
    const requiredKeys = ["articleNo"]
    let tempArticleList = []
    let tempDirectArticleList = []

    if (articleList) {
      tempArticleList = Object.entries(articleList).map((item) => {
        return {
          key: item[0],
          subItems: [
            {
              label: requiredKeys.includes(item[0]) ? t(item[0]) : item[0],
              content: String(item[1]),
            },
          ],
        }
      })
    }

    const tempGeneralArticleList = tempArticleList
      .concat(tempDirectArticleList)
      .filter((item) => requiredKeys.includes(item.key))

    return uniq(tempGeneralArticleList)
      .map((item) => item.subItems)
      .map((item) => item[0].content)
  }, [articleList, t])

  return (
    <ResponsiveBackground theme={theme}>
      <Dialog
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false)
        }}
        confirmStyle={{
          color: tokens.color.primary,
          label: t("confirm"),
        }}
        onAccept={() => {
          setIsDialogOpen(false)
          history.goBack()
        }}
        title={t("dialogTitle")}
        text={t("dialogText", { ean: localStorage.getItem("data") })}
        hideClose={true}
      />
      <Container sx={{ paddingX: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "0px 0px",
          }}
        >
          <Box
            sx={{
              backgroundColor: tokens.color.primary,
              width: "100%",
              height: "40px",
              display: "flex",
              textAlign: "center",
              alignItems: "center",
              borderRadius: "4px",
              color: "#ffff",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: 600,
                marginLeft: "10px",
              }}
              color={isAte ? "white" : "black"}
            >
              {generalArticleNumber[0]}
            </Typography>
          </Box>
          <Tabs
            isHomeSelected={isHomeSelected}
            icons={[
              { path: mdiCar },
              { path: mdiInformation },
              { path: mdiFileImage },
              { path: mdiFormatListBulletedSquare },
              { path: mdiOfficeBuilding },
              { path: mdiEarth, text: countryCatalogue },
            ]}
            labels={[
              { label: "" },
              { label: "" },
              { label: "" },
              { label: "" },
              { label: "" },
              { label: "" },
            ]}
            components={[
              {
                element: <Vehicles handleLoading={handleLoading} />,
                hasPadding: false,
              },
              { element: <Info />, hasPadding: false },
              { element: <Documents />, hasPadding: false },
              { element: <OE />, hasPadding: false },
              { element: <Manufacturer />, hasPadding: false },
              {
                element: <CountrySelection backToHome={backToHome} />,
                hasPadding: false,
              },
            ]}
            variant={"horizontal"}
            loading={loading}
          />
          {loading && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="10vh"
              sx={{ flexDirection: "column" }}
              marginBottom={theme.spacing(2)}
            >
              <CircularProgress
                style={{ color: tokens.color.primary }}
                size="5vh"
              />
            </Box>
          )}
        </Box>
      </Container>
    </ResponsiveBackground>
  )
}

export default TecDoc
