import React, { createContext, useContext } from "react"

import Store from "./store"

/* 
CONTEXT / PROVIDER INIT
*/

const AuthenticityCheckStoreContext = createContext<Store | null>(null)

export const StoreProvider: React.FC = (props) => {
  const { children } = props

  return (
    <AuthenticityCheckStoreContext.Provider value={new Store()}>
      {children}
    </AuthenticityCheckStoreContext.Provider>
  )
}

/* 
HOOK DEFINITION
*/

export const useAuthenticityCheckStore = () =>
  useContext(AuthenticityCheckStoreContext)
