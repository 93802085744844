function getOS() {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = null

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS"
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS"
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows"
  } else if (/Android/.test(userAgent)) {
    os = "Android"
  } else if (/Linux/.test(platform)) {
    os = "Linux"
  }

  return os
}

export const checkGTIN = (input) => {
  const pattern = new RegExp(
    "^[(\\[]01[)\\[]\\d{14}([(\\[]\\d+[)\\[][ A-Za-z0-9_@./#&+-]+)*[(\\[]21[)\\[][ A-Za-z0-9_@./#&+-]+([(\\[]\\d+[)\\[][ A-Za-z0-9_@./#&+-]+)*$"
  )
  return pattern.test(input)
}

export const getGeoLocation = () => {
  let geolocation
  let os = getOS()
  let timeout = os === "iOS" ? 5000 : 10000

  const geoLocationOptions = {
    enableHighAccuracy: true,
    timeout: timeout,
    maximumAge: 0,
  }

  // Dummy call, as second attempt is more reliable
  navigator.geolocation.getCurrentPosition(
    function () {},
    function () {},
    {}
  )

  if (navigator.geolocation) {
    geolocation = new Promise(function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          resolve([position.coords.latitude, position.coords.longitude])
        },
        function (err) {
          console.warn("Geolocation rejected")
          reject(err)
        },
        geoLocationOptions
      )
    })
  } else {
    console.warn("Geolocation disabled")
    geolocation = new Promise((resolve, reject) =>
      reject("Geolocation disabled")
    )
  }

  return Promise.race([
    geolocation,
    new Promise((resolve, reject) => {
      setTimeout(() => {
        reject("Geolocation disabled")
      }, 10000)
    }),
  ])
}

/*
Converts encoded UID to human-readable format
 */
export const convertToHRF = (uid, ais) => {
  let aiInfo
  let codeArray = []
  let hasFnc1 = false

  if (uid.includes("%5dd201")) {
    uid = uid.slice(5)
  }

  if (uid.includes("%5dd2%1D")) {
    uid = uid.slice(8)
  }

  while (uid.length > 0) {
    aiInfo = getNextAI(uid, ais.default)
    if (typeof aiInfo !== "undefined") {
      hasFnc1 = aiInfo.fnc1

      const prefixLength = aiInfo.name.length
      const aiEndIndex = hasFnc1
        ? uid.indexOf("%1D") !== -1
          ? uid.indexOf("%1D")
          : uid.length
        : prefixLength + aiInfo.length

      codeArray.push({
        name: aiInfo.name,
        value: uid.slice(prefixLength, aiEndIndex),
      })
      uid = hasFnc1
        ? uid.slice(aiEndIndex + "%1D".length)
        : uid.slice(aiEndIndex)
    } else {
      break
    }
  }
  return codeArray
}

const getNextAI = (uid, ais: [{ name; length; fnc1 }]) => {
  let aiInfo = undefined

  if (uid.slice(0, 3).includes("%1D")) uid = uid.slice(3)

  for (let i = 2; uid.length >= i; i++) {
    let prefix = uid.slice(0, i)
    aiInfo = ais.find((ai) => ai.name === prefix)
    if (typeof aiInfo !== "undefined") break
  }

  return aiInfo
}

export const isNumeric = (value) => {
  return /^-?\d+([.]\d+)*$/.test(value)
}
