import React, { useCallback, useRef } from "react"
import { Box, CircularProgress } from "@mui/material"
import { observer } from "mobx-react"
import { useEffect, useState } from "react"

import { useMatchedRoute } from "hooks"
import { useLocation } from "react-router-dom"
import { TRoute } from "types/Global"
import { isAte } from "utils/Global"
import { routes as useRoutes } from "../routes"

import { useTranslation } from "react-i18next"
import { StyledContent } from "../../components/Background/styles"
import TopBar from "components/TopBar"

/**  set webpack public path as otherwise the chunks of translations are not loaded correctly. 
/    It is not taking a relative path but always a static one. The reason why it is determined here dynamically
 */
__webpack_public_path__ = window.location.pathname
if (
  __webpack_public_path__.endsWith(".html") ||
  __webpack_public_path__.endsWith(".htm")
) {
  __webpack_public_path__ = __webpack_public_path__.substring(
    0,
    __webpack_public_path__.lastIndexOf("/") + 1
  )
} else if (window.location.hostname.includes("continental-aftermarket.com")) {
  __webpack_public_path__ = __webpack_public_path__.substring(
    0,
    __webpack_public_path__.lastIndexOf("/") + 1
  )
} else if (!__webpack_public_path__.endsWith("/")) {
  __webpack_public_path__ += "/"
}

const hideSplashScreen = () => {
  const splashscreen = document.getElementById("app-splashscreen")

  if (splashscreen) {
    splashscreen.className = ""
    setTimeout(() => {
      splashscreen.remove()
    }, 30)
  }
}
export const namespace = __filename

const RootRouter = () => {
  const portalContainerRef = useRef(null)

  const location = useLocation()
  const routes = [...useRoutes] as readonly TRoute[]
  const [fallbackRoute] = routes
  const { MatchedElement } = useMatchedRoute(routes, fallbackRoute.Component, {
    matchOnSubPath: true,
  })

  const { t } = useTranslation("app")
  const [pageTitle, setPageTitle] = useState("")

  const checkTopBarTitle = useCallback(() => {
    let translatedTitle

    switch (pageTitle) {
      case "Start screen":
      case "Echtheitsüberprüfung":
        translatedTitle = "titleStartScreenTopBar"
        break
      case "MAPPCode Screen":
      case "MAPPCode-Bildschirm":
        translatedTitle = "titleMAPPCheckTopBar"
        break
      case "Authenticity Check":
      case "Echtheitsprüfung":
        translatedTitle = "titleAuthenticityTopBar"
        break
      case "Scan EAN-Code":
      case "EAN Code scannen":
      case "TecDoc Catalogue":
      case "TecDoc Katalog":
        translatedTitle = "titleEANCheckTopBar"
        break
      case "Counterfeit Report":
      case "Fälschungsbericht":
        translatedTitle = "titleCounterfeitTopBar"
        break
      default:
        translatedTitle = "titleStartScreenTopBar"
    }

    return t(translatedTitle)
  }, [t, pageTitle])

  useEffect(() => {
    setPageTitle(t(`routes.${location.pathname}`))
    checkTopBarTitle()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, t])

  const loadingApp = false

  useEffect(() => {
    hideSplashScreen()
  }, [])

  React.useEffect(() => {
    const root = document.getElementById("portal-container")
    if (isAte) {
      // Set the --color-primary and --color-secondary variables
      root.style.setProperty("--color-primary", "#005CA9") // Change to the color you want
      root.style.setProperty("--color-secondary", "#005CA9") // Change to the color you want

      const fontFace = new FontFace(
        "Etelka Light",
        `url(assets/fonts/EtelkaLight.woff) format("woff")`
      )
      fontFace
        .load()
        .then(() => {
          document.fonts.add(fontFace)
          if (portalContainerRef.current) {
            portalContainerRef.current.style.fontFamily = "Etelka Light"
            portalContainerRef.current.style.fontWeight = 100
            portalContainerRef.current.style.fontStyle = "normal"
          }
        })
        .catch((error) => {
          console.log("Error loading Etelka Light font", error)
        })
    } else {
      const fontFace = new FontFace(
        "ContinentalStagSans-Light",
        `url(assets/fonts/ContinentalStagSans-Light.woff2) format("woff2")`
      )
      fontFace
        .load()
        .then(() => {
          document.fonts.add(fontFace)
          if (portalContainerRef.current) {
            portalContainerRef.current.style.fontFamily =
              "ContinentalStagSans-Light"
            portalContainerRef.current.style.fontWeight = 100
            portalContainerRef.current.style.fontStyle = "normal"
          }
        })
        .catch((error) => {
          console.log("Error loading ContinentalStagSans-Light font", error)
        })
    }
  }, [])

  return (
    <div
      id="portal-container"
      style={{
        fontFamily: isAte ? "Etelka Light" : "ContinentalStagSans-Light",
      }}
      ref={portalContainerRef}
    >
      {loadingApp ? (
        <Box display="flex" alignItems="center" justifyContent="center">
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <>
          <TopBar title={checkTopBarTitle()} />
          <StyledContent component="main">{MatchedElement}</StyledContent>
        </>
      )}
    </div>
  )
}

export default observer(RootRouter)
