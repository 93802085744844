import React, { useEffect, useState } from "react"

import { observer } from "mobx-react"

import { ListTypes } from "./types"
import { ListImageItem, ListTextItem } from "./components"
import ListHeading from "./components/ListHeading"
import { Box } from "@mui/material"
import { StyledAccordionList } from "./styles"

const List: React.FC<ListTypes> = (props) => {
  const { title, items, type, accordion = false } = props
  const [hidden, setHidden] = useState(accordion)
  const [labelWidth, setLabelWidth] = useState(null)
  const [widthsArray, setWidthsArray] = useState([])
  const updateLabelWidth = (value: number) => {
    setWidthsArray((oldArray) => [...oldArray, value])
  }

  useEffect(() => {
    const maxWidth = widthsArray.length > 0 ? Math.max(...widthsArray) : null
    setLabelWidth(maxWidth)
  }, [widthsArray])

  const buildList = (item) => {
    if (type === "IMAGE") {
      return (
        <ListImageItem
          key={item.key}
          subItems={item.subItems}
          hasBorder={item.hasBorder}
        />
      )
    } else if (type === "TEXT" || type === "LINK") {
      return (
        <ListTextItem
          key={item.key}
          subItems={item.subItems}
          hasBorder={item.hasBorder}
          onClick={item.onClick}
          labelWidth={labelWidth}
          updateLabelWidth={updateLabelWidth}
          hidden={hidden}
          show={!accordion}
        />
      )
    }
  }

  return (
    <>
      <Box onClick={() => setHidden(!hidden)}>
        <ListHeading
          title={title}
          caret={accordion ? (hidden ? "DOWN" : "UP") : "NONE"}
          variant={"SECONDARY"}
        />
      </Box>
      <StyledAccordionList display={hidden && accordion ? "none" : "block"}>
        {items.map((item) => buildList(item))}
      </StyledAccordionList>
    </>
  )
}

export default observer(List)
