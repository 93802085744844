import {
  Html5Qrcode,
  Html5QrcodeScanType,
  Html5QrcodeScannerState,
  Html5QrcodeSupportedFormats,
} from "html5-qrcode"
import { useEffect } from "react"
import "./html5-qrcode.css"

const qrcodeRegionId = "reader"

// Creates the configuration object for Html5QrcodeScanner.
const createConfig = (props) => {
  let config = {
    fps: 15,
    qrbox: {
      width: 400,
      height: 300,
    },
    aspectRatio: undefined,
    disableFlip: undefined,
    defaultZoomValueIfSupported: 1.7,
    showZoomSliderIfSupported: true,
    rememberLastUsedCamera: true,
    experimentalFeatures: {
      useBarCodeDetectorIfSupported: true,
    },
    supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
    formatsToSupport: [Html5QrcodeSupportedFormats.EAN_13],
  }
  if (props.fps) {
    config.fps = props.fps
  }
  if (props.qrbox) {
    config.qrbox = props.qrbox
  }
  if (props.aspectRatio) {
    config.aspectRatio = props.aspectRatio
  }
  if (props.disableFlip !== undefined) {
    config.disableFlip = props.disableFlip
  }
  return config
}

const Html5QrcodePlugin = (props) => {
  useEffect(() => {
    if (!props.qrCodeSuccessCallback) {
      throw new Error("qrCodeSuccessCallback is required callback.")
    }

    const config = createConfig(props)

    const html5QrCode = new Html5Qrcode(qrcodeRegionId)
    Html5Qrcode.getCameras().then((devices) => {
      if (devices && devices.length) {
        let cameraId = devices[0].id // Default to the first camera if specific criteria are not met
        if (devices.length === 1) {
          cameraId = devices[0].id
        } else {
          //check first for 2_0 camera as this is usally the default back one
          for (const device of devices) {
            const cameraLabel = device.label
            if (
              cameraLabel &&
              cameraLabel.toLowerCase().includes("back") &&
              (cameraLabel.toLowerCase().includes("2 0") ||
                cameraLabel.toLowerCase().includes("2.0"))
            ) {
              cameraId = device.id
            }
          }
          //if none is found take the first back one
          if (cameraId == null) {
            for (const device of devices) {
              const cameraLabel = device.label
              if (cameraLabel && cameraLabel.toLowerCase().includes("back")) {
                cameraId = device.id
              }
            }
          }
        }

        html5QrCode
          .start(
            cameraId,
            config,
            props.qrCodeSuccessCallback,
            props.qrCodeErrorCallback
          )
          .catch((err) => {
            console.error(`Unable to start camera with ID ${cameraId}`, err)
          })

        let videoConfig = {
          focusMode: "continuous",
          advanced: [{ zoom: 1.7 }],
        }
        startTimer(html5QrCode, videoConfig)
      }
    })

    return () => {
      if (html5QrCode.getState() === Html5QrcodeScannerState.SCANNING) {
        html5QrCode.stop().catch((error) => {
          console.error("Failed to stop html5QrCode. ", error)
        })
      }
    }
  }, [props])

  return <div id={qrcodeRegionId} style={{ width: 300, height: 400 }} />
}

const startTimer = (html5QrCode, videoConfig) => {
  setTimeout(function () {
    if (html5QrCode.getState() === Html5QrcodeScannerState.SCANNING) {
      html5QrCode.applyVideoConstraints(videoConfig)
    } else {
      startTimer(html5QrCode, videoConfig)
    }
  }, 2000)
}

export default Html5QrcodePlugin
