import { FlagTypes } from "./types"
import Image from "components/Image"

const CheckResultFlags: React.FC<FlagTypes> = ({ flag }) => {
  const imageStrings = {
    GREEN:
      "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTIiIGhlaWdodD0iNTEiIHZpZXdCb3g9IjAgMCA1MiA1MSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjUyIiBoZWlnaHQ9IjUxIiByeD0iMjUuNSIgZmlsbD0iIzQ4OTI1OCIvPgo8cGF0aCBkPSJNMTMuNSAyNUwyMi4xNzM1IDMzLjVMMzguNSAxNy41IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjMiLz4KPC9zdmc+Cg==",
    YELLOW:
      "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTIiIGhlaWdodD0iNTEiIHZpZXdCb3g9IjAgMCA1MiA1MSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjUyIiBoZWlnaHQ9IjUxIiByeD0iMjUuNSIgZmlsbD0iI0Y3OTQwMCIvPgo8cGF0aCBkPSJNMjcuNTY4MiAxMy43MjczTDI3LjI3MjcgMzAuMzA2OEgyNC4xMjVMMjMuODI5NSAxMy43MjczSDI3LjU2ODJaTTI1LjcwNDUgMzcuMjE1OUMyNS4wNzU4IDM3LjIxNTkgMjQuNTM3OSAzNi45OTYyIDI0LjA5MDkgMzYuNTU2OEMyMy42NDM5IDM2LjEwOTggMjMuNDI0MiAzNS41NzIgMjMuNDMxOCAzNC45NDMyQzIzLjQyNDIgMzQuMzIyIDIzLjY0MzkgMzMuNzkxNyAyNC4wOTA5IDMzLjM1MjNDMjQuNTM3OSAzMi45MDUzIDI1LjA3NTggMzIuNjgxOCAyNS43MDQ1IDMyLjY4MThDMjYuMzE4MiAzMi42ODE4IDI2Ljg0ODUgMzIuOTA1MyAyNy4yOTU1IDMzLjM1MjNDMjcuNzQyNCAzMy43OTE3IDI3Ljk2OTcgMzQuMzIyIDI3Ljk3NzMgMzQuOTQzMkMyNy45Njk3IDM1LjM1OTggMjcuODU5OCAzNS43NDI0IDI3LjY0NzcgMzYuMDkwOUMyNy40NDMyIDM2LjQzMTggMjcuMTcwNSAzNi43MDQ1IDI2LjgyOTUgMzYuOTA5MUMyNi40ODg2IDM3LjExMzYgMjYuMTEzNiAzNy4yMTU5IDI1LjcwNDUgMzcuMjE1OVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=",
    RED: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTIiIGhlaWdodD0iNTEiIHZpZXdCb3g9IjAgMCA1MiA1MSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CjxyZWN0IHdpZHRoPSI1MiIgaGVpZ2h0PSI1MSIgcng9IjI1LjUiIGZpbGw9IiNDRjZBNkEiLz4KPHJlY3QgeD0iMTMuNSIgeT0iMTMuNSIgd2lkdGg9IjI1IiBoZWlnaHQ9IjI0IiBmaWxsPSJ1cmwoI3BhdHRlcm4wKSIvPgo8ZGVmcz4KPHBhdHRlcm4gaWQ9InBhdHRlcm4wIiBwYXR0ZXJuQ29udGVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgd2lkdGg9IjEiIGhlaWdodD0iMSI+Cjx1c2UgeGxpbms6aHJlZj0iI2ltYWdlMF8xNThfNDUxMiIgdHJhbnNmb3JtPSJtYXRyaXgoMC4wMSAwIDAgMC4wMTA0MTY3IDAuMDIgMCkiLz4KPC9wYXR0ZXJuPgo8aW1hZ2UgaWQ9ImltYWdlMF8xNThfNDUxMiIgd2lkdGg9Ijk2IiBoZWlnaHQ9Ijk2IiB4bGluazpocmVmPSJkYXRhOmltYWdlL3BuZztiYXNlNjQsaVZCT1J3MEtHZ29BQUFBTlNVaEVVZ0FBQUdBQUFBQmdDQVlBQUFEaW1IYzRBQUFBQ1hCSVdYTUFBQXNUQUFBTEV3RUFtcHdZQUFBQ1VFbEVRVlI0bk8yY1FVN2NRQkJGTzlzZ2RnRUd1RWcyT2V2Y2hCV0lSVllRSU1TblFNb0JYb1N3RlRFQzNEWWVWMWZWZitzWnFmczl0NjJadGwyS0VFSUlJWVFRUWdnaGhCQ1ZBQWZBRmpqTElnMDQ2K2Q4WUQyUXI4QUZMM1RBZVFrT3NBRWUramxmQW9jdHlDZERCRjdMeHl6Q08vSkRSK0J0K2V0SEdKRWZNZ0lmeTE4M1FuL3hxU0ZFQk9DOG4wc04yelVHZEFMY1o0aEEzWkcvL2x3elJLQlYrUmtpMExyOHlCSHdJajlpQkx6Smp4UUJyL0lqUk1DN2ZNOFJpQ0xmWXdTaXlmY1VnYWp5UFVRZ3V2eVdJNUJGZm9zUnlDYS9wUWhrbGI4VHdVUUEyZVZiaWtEeTdZUWcrWFppa0h3N1FVaStuU2drZnhvc0tFenlaOElDRVNUL2svQ0pDSksvRU15SUlQbTJOMEoxRXo4Yjh4ZnUwakJ0SlVoKzR4RTZIZmwyRVRySnQxc0puZVRiUmVna2YwRlFBRHZRS2NqTkx0cGI2RlMwMGo3eVJ5aUNvZndCUlpqNEVQUWpkZWl2Q092YlY5QUdqSjM4OHYrN3V1M0VTdjZBSWpSdzF4eGFDWHVWLzBlYjhzdkx2NXNndi9wMU9Gb0o0NEtPOXlWL1FCRU01UThvZ3FIOEFVV1lKLzl4eVZlZ3BZOWdLYjlrajlETC8yVXBQMjJFbHVTbmk5Q2kvRFFSWnNnL05SampwcFduTjlQSmIrbnB6VVVCanJ6SUR4ZWhsMzliT1pIZkxjZ1BFOEd6ZlBjUklzaDNHeUdTZkhjUklzcDNFMkdHL0UxeEJxMUd5Q0MvMlFqQUYrQzZja0RQdndlT1NxNW4xbjQrTzlyM2dINEFmMGNHOHVENXlKKzVFcDZBNzJVTlJpS0VrbDhaWVQzNUl4RkN5aCtKc0w3OGR5TGNBTjlLY0hnZHdVNytUb1NyRFBKM0lseWJ5eC9ZKzVXL1FUTE9XUWdoaEJCQ0NDR0VFTVU3L3dCbGorQ3ZzTkozSEFBQUFBQkpSVTVFcmtKZ2dnPT0iLz4KPC9kZWZzPgo8L3N2Zz4K",
  }

  return <Image image={imageStrings[flag]} size={50} />
}

export default CheckResultFlags
