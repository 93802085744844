import React, { useEffect, useRef } from "react"

import { StyledListItem, StyledSubListItem } from "./styles"
import { Box, Link, Typography, useTheme } from "@mui/material"
import Icon from "@mdi/react"
import { ListTextItemTypes } from "../types"
import { mdiChevronDown, mdiChevronUp } from "@mdi/js"
import { convertSizeRem } from "utils/util"
import { isValidUrl } from "pages/util"

const ShowButton = (props) => {
  const { isOpen, setIsOpen } = props
  return (
    <Box onClick={() => setIsOpen(!isOpen)}>
      <Icon
        path={isOpen ? mdiChevronUp : mdiChevronDown}
        size={"2rem"}
        style={{ marginLeft: "auto", color: "black" }}
      />
    </Box>
  )
}

const processLink = (link) => {
  const prefix = !(link.startsWith("http://") || link.startsWith("https://"))
    ? "https://"
    : ""
  const fullLink = prefix + link

  if (fullLink.length > 28) {
    const middle = Math.ceil(fullLink.length / 2)
    return [fullLink.substring(0, middle), fullLink.substring(middle)]
  }

  return [fullLink]
}

const ListTextItem: React.FC<ListTextItemTypes> = (props) => {
  const {
    subItems,
    hasBorder,
    labelWidth,
    updateLabelWidth,
    onClick,
    hidden,
    show: initialShow = true,
  } = props
  const theme = useTheme()
  const [show, setShow] = React.useState(initialShow)

  return (
    <>
      <StyledListItem
        borderBottom={hasBorder ? `2px solid ${theme.palette.info.main}` : ""}
        onClick={onClick}
        theme={theme}
      >
        {subItems.map((item) => (
          <React.Fragment key={item.label}>
            {item.separator && (item.hasBoldLabel || show) && (
              <div
                style={{
                  borderBottom: `2px solid ${theme.palette.grey.A200}`,
                  marginBlock: theme.spacing(1),
                }}
              />
            )}
            <StyledSubListItem
              justifyContent={"flex-start"}
              gap={theme.spacing(3)}
              style={{ justifyContent: "space-between" }}
            >
              {(item.hasBoldLabel || show) && !item.separator && (
                <ListTextItemLabel
                  hidden={hidden}
                  item={item}
                  labelWidth={labelWidth}
                  updateLabelWidth={updateLabelWidth}
                />
              )}
              {item.content && show ? (
                isValidUrl(item.content) ? (
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    {item.content.includes("@") && item.content.length > 28 ? (
                      (item.content as string).split("@").map((value, i) => (
                        <Link
                          key={`${value}-${i}`}
                          href={`${
                            !(
                              item.content.startsWith("http://") ||
                              item.content.startsWith("https://")
                            )
                              ? "https://"
                              : ""
                          }${item.content}`}
                        >
                          {value + (i === 0 ? "@" : "")}
                        </Link>
                      ))
                    ) : item.content.includes("www.") &&
                      item.content.length > 28 ? (
                      processLink(item.content).map((value, i) => (
                        <Link
                          key={`${value}-${i}`}
                          href={`${
                            !(
                              item.content.startsWith("http://") ||
                              item.content.startsWith("https://")
                            )
                              ? "https://"
                              : ""
                          }${item.content}`}
                        >
                          {value}
                        </Link>
                      ))
                    ) : (
                      <Link
                        href={`${
                          !(
                            item.content.startsWith("http://") ||
                            item.content.startsWith("https://")
                          )
                            ? "https://"
                            : ""
                        }${item.content}`}
                      >
                        {item.content}
                      </Link>
                    )}
                  </Box>
                ) : (
                  <Typography
                    onClick={() => {}}
                    style={{
                      textAlign: "right",
                      fontSize: convertSizeRem(0.8),
                    }}
                  >
                    {item.content}
                  </Typography>
                )
              ) : (
                <></>
              )}
              {item.hasBoldLabel && (
                <ShowButton isOpen={show} setIsOpen={setShow} />
              )}
            </StyledSubListItem>
          </React.Fragment>
        ))}
      </StyledListItem>
    </>
  )
}

const ListTextItemLabel = (props) => {
  const ref = useRef(null)
  const { item, labelWidth, updateLabelWidth, hidden } = props

  useEffect(() => {
    // Add 1 to make sure values rounded down are not cut off
    if (!hidden && !labelWidth && !item?.autoWidth) {
      updateLabelWidth(ref.current ? ref.current.offsetWidth + 1 : 0)
    }
    // eslint-disable-next-line
  }, [hidden])

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {(item.label as string).split("/").map((value, index) => (
        <Typography
          key={index}
          sx={{
            fontWeight: item.hasBoldLabel ? "bold" : "normal",
            flexBasis: "auto",
          }}
          variant={"body1"}
          ref={ref}
        >
          {value}
        </Typography>
      ))}
    </Box>
  )
}
export default ListTextItem
