import { isAte } from "utils/Global"

export const isUndefined = (value) => {
  return typeof value === "undefined"
}

export const scrolltToTop = () => {
  scrollToPos()
  setTimeout(() => {
    scrollToPos()
  }, 100)
}

const scrollToPos = () => {
  const scrollAnchor = document.getElementById("scroll_anchor")

  let topPos = 0
  let titleTop = 0

  const mainNav = document.querySelector(".main-navigation") as HTMLElement
  const metaNav = document.querySelector(".meta-navigation") as HTMLElement
  const ateMetaNav = document.getElementById("meta-bar") as HTMLElement
  const ateLogoMetaNav = document.getElementById("logo") as HTMLElement
  const ateLogoMetaNavBar = document.getElementById("nav-bar") as HTMLElement

  if (mainNav && metaNav) {
    const mainNavHeight = mainNav.offsetHeight
    const metaNavHeight = metaNav.offsetHeight
    topPos = mainNavHeight + metaNavHeight
  }
  if (isAte && ateMetaNav) {
    const top = ateMetaNav.getBoundingClientRect().top
    const metaNavTop = ateMetaNav.offsetHeight + top
    let logoNavTop = 0
    let navBarTop = 0

    if (ateLogoMetaNav) {
      const logoHeight = ateLogoMetaNav.offsetHeight
      if (logoHeight > ateMetaNav.offsetHeight) {
        logoNavTop = logoHeight + top
      }
    }
    if (ateLogoMetaNavBar) {
      const logoHeight = ateLogoMetaNavBar.offsetHeight
      navBarTop = logoHeight + ateLogoMetaNavBar.getBoundingClientRect().top
    }
    topPos = Math.max(topPos, metaNavTop, logoNavTop, navBarTop)
  }

  let anchorTop = scrollAnchor.getBoundingClientRect().top
  if (anchorTop < 0) {
    anchorTop = 0
  }
  if (scrollAnchor) {
    let scrollAnchorTop = scrollAnchor.getBoundingClientRect().top
    scrollAnchorTop = scrollAnchorTop < 0 ? scrollAnchorTop : 0
    const scrollPos =
      window.scrollY + scrollAnchorTop + titleTop - topPos + anchorTop - 3
    window.scrollTo({ top: scrollPos, behavior: "smooth" })
  }
}

export const convertSizeRem = (size) => {
  if (isAte) {
    return size * 1.5 + "rem"
  }
  return size + "rem"
}
