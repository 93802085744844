import {
  Box,
  Button,
  Container,
  FormControl,
  Typography,
  useTheme,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { generatePath, useHistory } from "react-router-dom"
import Dialog from "../../components/Dialog"
import ResponsiveBackground from "../../components/ResponsiveBackground"

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import ContiSpecificButton from "components/ContiSpecificButton"
import Html5QrcodePlugin from "components/Html5-qrcode/Html5qrcodePlugin"
import { EanScannerIos } from "ean-scanner-ios"
import "ean-scanner-ios/dist/index.css"
import useScopedTranslation from "../../hooks/useScopedTranslation"
import en from "./locales/en.json"
import { StyledTextField } from "./styles"

import { ateEanCode, contiEanCode, isAte, isConti } from "utils/Global"
import { default as general_eancode_image } from "../../assets/images/general_eancode.png"

export const namespace = __filename

const SecondScan = () => {
  const theme = useTheme()
  const history = useHistory()
  const { t } = useScopedTranslation(
    namespace,
    (locale) => import(`./locales/${locale}.json`),
    en
  )

  const isMobile = window.innerWidth < theme.tokens.breakpoints.breakpointTablet

  const [data, setData] = useState("")
  const [scan, setScan] = useState(false)
  const [show, setShow] = useState(false)
  const [error, setError] = useState("")
  const [eanCodeImg, setEanCodeImg] = React.useState(general_eancode_image)
  const [showInfo, setShowInfo] = useState(false)
  const [showTextField, setShowTextField] = useState(!isMobile ? true : null)
  const [showPlaceholder, setShowPlaceholder] = useState(true)

  //start quagga
  const [decodedText, setDecodedText] = useState("" as string)
  const [scanList, setScanList] = useState([])

  const redirectLocation = (gtin) => {
    setError("")
    history.replace({
      pathname: "/scan",
      search: "ean=" + gtin,
    })
    history.push({
      pathname: generatePath("/tecdoc"),
      state: {
        gtin: gtin,
      },
    })
  }

  useEffect(() => {
    if (history.location.search) {
      const parameters = new URLSearchParams(history.location.search)
      const ean = parameters.get("ean")
      if (ean && ean.length > 3) {
        setShowTextField(true)
        setDecodedText(parameters.get("ean"))
        history.replace({
          pathname: "/scan",
          search: "",
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      eanCheck(decodedText ? decodedText : data) && redirectLocation(data)
    }
  }

  const onClickHandle = () => {
    eanCheck(decodedText ? decodedText : data) && redirectLocation(data)
  }

  const eanCheck = (input) => {
    if (!/^\d{13}$/.test(input)) {
      setError(t("eanIncorrectLength"))
      return false
    } else {
      setError("")
    }

    // Calculate the check digit using the first 12 digits
    let checkDigit = 0
    for (let i = 0; i < 12; i += 2) {
      checkDigit += Number(input[i])
      checkDigit += Number(input[i + 1]) * 3
    }
    checkDigit = (10 - (checkDigit % 10)) % 10

    const isCorrectDigit = checkDigit === Number(input[12])
    !isCorrectDigit && setError(`${t("eanIncorrectCheck")} ${checkDigit}`)
    return isCorrectDigit
  }

  useEffect(() => {
    if (isAte) {
      setEanCodeImg(ateEanCode)
    } else if (isConti) {
      setEanCodeImg(contiEanCode)
    } else {
      setEanCodeImg(contiEanCode)
    }
  }, [])

  const handleShowInfo = () => {
    setShowInfo(!showInfo)
  }

  //initiate ios scanner

  const getMostRepeated = (array) => {
    if (array.length === 0) return null
    let modeMap = {}
    let maxEl = array[0]
    let maxCount = 1
    for (let i = 0; i < array.length; i++) {
      let el = array[i]
      if (modeMap[el] == null) modeMap[el] = 1
      else modeMap[el]++
      if (modeMap[el] > maxCount) {
        maxEl = el
        maxCount = modeMap[el]
      }
    }
    return maxEl
  }

  //check operating system in order to render the functioning scanner
  const getScannerByOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor

    if (/android/i.test(userAgent)) {
      return (
        <Html5QrcodePlugin
          fps={10}
          qrbox={250}
          disableFlip={false}
          qrCodeSuccessCallback={onNewScanResultAndroid}
        />
      )
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return (
        <EanScannerIos
          onResult={(rawScan) => {
            if (
              (scanList.length === 2 && scanList[0] === scanList[1]) ||
              scanList.length > 3
            ) {
              const result = getMostRepeated(scanList)
              localStorage.setItem("data", result)
              setDecodedText(result)
              setScan(false)
              setShowTextField(true)
              redirectLocation(result)
            } else {
              if (eanCheck(rawScan)) {
                setScanList([...scanList, rawScan])
              }
            }
          }}
        />
      )
    }

    return "Unknown"
  }

  //result scanning android system
  const onNewScanResultAndroid = (decodedTextNew, decodedResult) => {
    if (decodedTextNew) {
      localStorage.setItem("data", decodedTextNew)
      setDecodedText(decodedTextNew)

      setScan(false)
      eanCheck(decodedTextNew ? decodedTextNew : data) &&
        redirectLocation(decodedTextNew)
      setShowTextField(true)
    }
  }
  const handleShowTextField = () => {
    setShowTextField(!showTextField)
  }

  const handleTextFieldChange = (e) => {
    setDecodedText(e.target.value)
    setData(e.target.value)
    localStorage.setItem("data", e.target.value)
  }

  return (
    <ResponsiveBackground theme={theme}>
      <Container
        sx={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            flexDirection: "column",
            padding: "0px 20px",
          }}
        >
          <Box>
            <Box>
              {window.innerWidth <
                theme.tokens.breakpoints.breakpointTablet + 200 && (
                <>
                  {!isMobile && (
                    <Typography sx={{ marginBottom: "20px" }} variant={"body1"}>
                      {t("helpText")}
                    </Typography>
                  )}
                  <Box
                    width={
                      window.innerWidth <
                      theme.tokens.breakpoints.breakpointTablet
                        ? "243px"
                        : "100%"
                    }
                    height={
                      window.innerWidth <
                      theme.tokens.breakpoints.breakpointTablet
                        ? "45px"
                        : null
                    }
                  >
                    <ContiSpecificButton
                      text={t("scanCode")}
                      onClick={() => setScan(true)}
                      isBarcode={true}
                    ></ContiSpecificButton>
                  </Box>
                  <Box
                    marginTop={"20px"}
                    marginRight={"0px"}
                    marginBottom={"5px"}
                    marginLeft={isMobile ? "3px" : "0px"}
                    alignItems={"end"}
                    display={"flex"}
                  >
                    <Button
                      onClick={handleShowTextField}
                      style={{
                        color: "#000",
                        padding: "2px",
                        textTransform: "none",
                      }}
                    >
                      <Typography>{t("manualEntry")}</Typography>
                      <ArrowForwardIosIcon style={{ fontSize: "15px" }} />
                    </Button>
                  </Box>
                </>
              )}

              <Box sx={{ display: !showTextField ? "none" : null }}>
                {window.innerWidth >
                  theme.tokens.breakpoints.breakpointTablet && (
                  <Typography
                    sx={{ marginBottom: "15px", marginTop: "10px" }}
                    variant={"body1"}
                  >
                    {t("helpTextTablet")}
                  </Typography>
                )}
                <FormControl fullWidth>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexFlow:
                        window.innerWidth <
                        theme.tokens.breakpoints.breakpointTablet
                          ? "row wrap"
                          : null,
                      alignItems: "center",
                    }}
                  >
                    <StyledTextField
                      fullWidth={
                        window.innerWidth >
                        theme.tokens.breakpoints.breakpointTablet
                      }
                      id="outlined-basic"
                      variant="outlined"
                      placeholder={showPlaceholder ? "4 006633 444729" : ""}
                      onChange={handleTextFieldChange}
                      value={decodedText}
                      type={"text"}
                      onKeyDown={handleKeyDown}
                      InputLabelProps={{ shrink: false }}
                      inputProps={{ min: 0, style: { textAlign: "center" } }}
                      InputProps={{
                        style: {
                          borderRadius: "30px",
                          marginTop: "15px",
                          textAlign: "center",
                          fontFamily: isAte
                            ? "Etelka Light"
                            : "ContinentalStagSans-Light",
                          fontSize: "18px",
                          lineHeight: "26px",
                          letterSpacing: "3px",
                          width: "100%",
                          height:
                            window.innerWidth <
                            theme.tokens.breakpoints.breakpointTablet
                              ? "32px"
                              : undefined,
                          minWidth: "250px",
                          padding: null,
                          backgroundColor: "#ffff",
                        },
                      }}
                      style={{
                        minWidth: "200px",
                      }}
                      onFocus={() => setShowPlaceholder(false)}
                      onBlur={() => setShowPlaceholder(true)}
                    />
                    <Box sx={{ marginLeft: "15px", margnTop: "10px" }}>
                      <ContiSpecificButton
                        onClick={onClickHandle}
                        isForwardIcon={true}
                        width="30px"
                        isFlexFlow={true}
                      ></ContiSpecificButton>
                    </Box>
                  </Box>
                </FormControl>
                <Typography
                  sx={{ textAlign: "left" }}
                  variant={"body2"}
                  color={theme.palette.error.main}
                >
                  {error}
                </Typography>
              </Box>
            </Box>

            <Box />
          </Box>
          <Box sx={{ padding: "30px 0px", paddingLeft: "0px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <InfoOutlinedIcon onClick={handleShowInfo} />

              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  marginLeft: "5px",
                }}
              >
                {t("EANCodeInfo")}
              </Typography>
            </Box>
            <Box sx={{ paddingTop: "10px", paddingLeft: "10px" }}>
              <Typography
                sx={{
                  marginBottom: "20px",
                  display: !showInfo ? "none" : null,
                }}
              >
                {t("EANCodeInfoProduct")}
              </Typography>
              {showInfo ? (
                <img
                  src={eanCodeImg}
                  width="70%"
                  height={"70%"}
                  alt=""
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0 auto",
                    padding: "10px 0px",
                    maxWidth: "400px",
                  }}
                ></img>
              ) : null}
            </Box>
          </Box>

          <Dialog
            open={scan || show}
            onClose={() => {
              setShow(false)
              setScan(false)
            }}
            closeStyle={{
              color: theme.palette.text.primary,
              label: t("close"),
            }}
            confirmStyle={{
              color: theme.palette.primary.main,
              label: t("confirm"),
            }}
            onAccept={
              show
                ? () => {
                    redirectLocation(data)
                  }
                : null
            }
            title={show ? t("codeScanned") : t("scanCode")}
            text={""}
            content={scan && <>{getScannerByOperatingSystem()}</>}
          />
        </Box>
      </Container>
    </ResponsiveBackground>
  )
}

export default SecondScan
