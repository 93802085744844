import { TypographyProps } from "@mui/material"
import React from "react"
import { TextProps } from "./types"
import { StyledBackground } from "./styles"

const ResponsiveBackground: React.FC<TextProps & TypographyProps> = (props) => {
  return <StyledBackground children={props.children} theme={props.theme} />
}

export default ResponsiveBackground
