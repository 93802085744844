import { makeAutoObservable } from "mobx"

export default class AppStore {
  sidebarOpen: boolean = false

  // init function
  constructor() {
    makeAutoObservable(this)
  }
}
