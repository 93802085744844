import { makeAutoObservable, runInAction } from "mobx"
import {
  ActionError,
  ActionResultStatus,
  ActionSuccess,
  QueryResponse,
} from "types/Global"
import { resultOrError } from "../../../utils/Global"
import { APIMethods, call } from "api/CloudAPI"
import {
  ArticleAttributes,
  ArticleDocuments,
  ArticleInfo,
  BrandDetails,
  CountryInfo,
  DirectArticle,
  EanNumber,
  OENumber,
  ReplacedNumber,
  VehicleDetails,
  VehicleImmediateAttributs,
  VehicleLink,
} from "../../../types/BusinessObjects"

class TecDocStore {
  loadingCountryList = false
  countryList: CountryInfo[] = null

  loadingArticleList = false
  articleList: ArticleInfo = null

  loadingBrandDetails = false
  brandDetails: BrandDetails = null

  dynamicIpAdded = false

  loadingArticleDetailsList = false
  articleAttributes: ArticleAttributes = null
  directArticle: DirectArticle = null
  eanNumber: EanNumber = null
  oeNumber: OENumber[] = null
  articleDocuments: ArticleDocuments[] = null
  replacedNumber: ReplacedNumber = null

  loadingVehicleList = false
  vehicleList: VehicleLink[] = null

  loadingVehicleImmediateAttributs = false
  vehicleListImmediateAttributs: VehicleImmediateAttributs[] = null

  loadingVehicleDetailsList = false
  vehicleDetailsList: VehicleDetails[] = null

  // init function
  constructor() {
    makeAutoObservable(this)
  }
  reset() {
    this.loadingCountryList = false
    this.countryList = null

    this.loadingArticleList = false
    this.articleList = null

    this.loadingBrandDetails = false
    this.brandDetails = null

    this.dynamicIpAdded = false

    this.loadingArticleDetailsList = false
    this.articleAttributes = null
    this.directArticle = null
    this.eanNumber = null
    this.oeNumber = null
    this.articleDocuments = null
    this.replacedNumber = null

    this.loadingVehicleList = false
    this.vehicleList = null

    this.loadingVehicleImmediateAttributs = false
    this.vehicleListImmediateAttributs = null

    this.loadingVehicleDetailsList = false
    this.vehicleDetailsList = null
  }

  // actions
  async fetchCountries(lang: string) {
    this.loadingCountryList = true

    const body = {
      function: APIMethods.TECDOC_GETCOUNTRIES,
      payload: {
        lang,
      },
    }

    const [result, error]: any = await resultOrError<QueryResponse<any>>(
      call(
        "TecDoc",
        {
          ...body,
        },
        "Continental_Aftermarket"
      )
    )

    if (result) {
      runInAction(() => {
        this.countryList = result.data.array
        this.loadingCountryList = false
      })
      return {
        status: ActionResultStatus.SUCCESS,
        result: result,
      } as ActionSuccess<number>
    } else {
      runInAction(() => {
        this.loadingCountryList = false
      })
      return {
        status: ActionResultStatus.ERROR,
        error: error,
      } as ActionError
    }
  }

  // actions
  async fetchArticle(
    articleCountry: string,
    lang: string,
    numberType: number,
    articleNumber: string
  ) {
    this.loadingArticleList = true

    const body = {
      function: APIMethods.TECDOC_GETARTICLE,
      payload: {
        lang,
        articleCountry,
        numberType,
        articleNumber,
      },
    }

    const [result, error]: any = await resultOrError<QueryResponse<any>>(
      call(
        "TecDoc",
        {
          ...body,
        },
        "Continental_Aftermarket"
      )
    )

    if (result) {
      runInAction(() => {
        this.loadingArticleList = false
        this.articleList = result.data.array[0]
      })
      return {
        status: ActionResultStatus.SUCCESS,
        result: result,
      } as ActionSuccess<number>
    } else {
      runInAction(() => {
        this.loadingArticleList = false
      })
      return {
        status: ActionResultStatus.ERROR,
        error: error,
      } as ActionError
    }
  }

  async fetchBrandDetails(
    articleCountry: string,
    lang: string,
    brandNo: number
  ) {
    this.loadingBrandDetails = true

    const body = {
      function: APIMethods.TECDOC_GETBRANDDETAILS,
      payload: {
        lang,
        articleCountry,
        brandNo,
      },
    }

    const [result, error]: any = await resultOrError<QueryResponse<any>>(
      call(
        "TecDoc",
        {
          ...body,
        },
        "Continental_Aftermarket"
      )
    )

    if (result) {
      runInAction(() => {
        this.brandDetails = result.data.array[0]
        this.loadingBrandDetails = false
      })
      return {
        status: ActionResultStatus.SUCCESS,
        result: result,
      } as ActionSuccess<number>
    } else {
      runInAction(() => {
        this.loadingBrandDetails = false
      })
      return {
        status: ActionResultStatus.ERROR,
        error: error,
      } as ActionError
    }
  }

  async fetchArticleDetails(
    articleCountry: string,
    lang: string,
    articleId: number
  ) {
    this.loadingArticleDetailsList = true

    const body = {
      function: APIMethods.TECDOC_GETARTICLEDETAILS,
      payload: {
        lang,
        articleCountry,
        articleId: {
          array: [articleId],
        },
        attributs: true,
        basicData: true,
        documents: true,
        eanNumbers: true,
        oeNumbers: true,
        replacedNumbers: true,
        usageNumbers: true,
      },
    }

    const [result, error]: any = await resultOrError<QueryResponse<any>>(
      call(
        "TecDoc",
        {
          ...body,
        },
        "Continental_Aftermarket"
      )
    )

    if (result) {
      runInAction(() => {
        this.loadingArticleDetailsList = false
        this.articleAttributes = result.data.array[0].articleAttributes?.array
        this.directArticle = result.data.array[0]?.directArticle
        this.eanNumber = result.data.array[0].eanNumber?.array
        this.replacedNumber = result.data.array[0].replacedNumber
        this.loadingArticleDetailsList = false
        this.articleDocuments = result.data.array[0].articleDocuments.array
        this.oeNumber = result.data.array[0].oenNumbers.array
      })
      return {
        status: ActionResultStatus.SUCCESS,
        result: result,
      } as ActionSuccess<number>
    } else {
      runInAction(() => {
        this.loadingArticleDetailsList = false
      })
      return {
        status: ActionResultStatus.ERROR,
        error: error,
      } as ActionError
    }
  }

  async addPublicIP(address: string) {
    const body = {
      function: APIMethods.TECDOC_ADDIPADDRESS,
      payload: {
        address: address,
        validityHours: 1,
      },
    }

    const [result, error]: any = await resultOrError<QueryResponse<any>>(
      call(
        "TecDoc",
        {
          ...body,
        },
        "Continental_Aftermarket"
      )
    )

    if (result) {
      runInAction(() => {
        this.dynamicIpAdded = result.hasOwnProperty("validityHours")
      })
      return {
        status: ActionResultStatus.SUCCESS,
        result: result,
      } as ActionSuccess<number>
    } else {
      return {
        status: ActionResultStatus.ERROR,
        error: error,
      } as ActionError
    }
  }

  async fetchVehicleList(
    articleCountry: string,
    lang: string,
    articleId: number
  ) {
    this.loadingVehicleList = true

    const body = {
      function: APIMethods.TECDOC_GETVEHICLELIST,
      payload: {
        lang,
        articleCountry,
        articleId: articleId,
        linkingTargetType: "PO", // P: Passenger car O: Commercial vehicle
      },
    }

    const [result, error]: any = await resultOrError<QueryResponse<any>>(
      call(
        "TecDoc",
        {
          ...body,
        },
        "Continental_Aftermarket"
      )
    )

    if (result) {
      runInAction(() => {
        this.vehicleList = result.data.array[0].articleLinkages?.array
        this.loadingVehicleList = false
      })
      return {
        status: ActionResultStatus.SUCCESS,
        result: result,
      } as ActionSuccess<number>
    } else {
      runInAction(() => {
        this.loadingVehicleList = false
      })
      return {
        status: ActionResultStatus.ERROR,
        error: error,
      } as ActionError
    }
  }

  async fetchVehicleDetailList(
    articleCountry: string,
    lang: string,
    carIds: number[]
  ) {
    this.loadingVehicleDetailsList = true
    let detailedVehicleArray = []
    let lastResult = null

    while (carIds.length !== 0) {
      let tempVehicleIds = carIds.splice(0, 24)

      const body = {
        function: APIMethods.TECDOC_GETVEHICLEDETAILSLIST,
        payload: {
          lang,
          articleCountry,
          carIds: {
            array: tempVehicleIds,
          },
          countriesCarSelection: articleCountry,
          country: articleCountry,
          motorCodes: true,
        },
      }

      const [result, error]: any = await resultOrError<QueryResponse<any>>(
        call(
          "TecDoc",
          {
            ...body,
          },
          "Continental_Aftermarket"
        )
      )

      if (result) {
        lastResult = result
        detailedVehicleArray = detailedVehicleArray.concat(result.data.array)
      } else {
        runInAction(() => {
          this.loadingVehicleDetailsList = false
        })
        return {
          status: ActionResultStatus.ERROR,
          error: error,
        } as ActionError
      }
    }
    if (detailedVehicleArray && lastResult) {
      runInAction(() => {
        this.vehicleDetailsList = detailedVehicleArray
        this.loadingVehicleDetailsList = false
      })
      return {
        status: ActionResultStatus.SUCCESS,
        result: lastResult,
      } as ActionSuccess<number>
    }
  }

  async fetchVehicleListImmediateAttributs(
    articleCountry: string,
    lang: string,
    linkedArticlePairs: { articleLinkId: number; linkingTargetId: number }[],
    articleId: number
  ) {
    this.loadingVehicleImmediateAttributs = true
    let detailedVehicleArray = []
    let lastResult = null

    while (linkedArticlePairs.length !== 0) {
      let tempVehicleIds = linkedArticlePairs.splice(0, 24)

      const body = {
        function: APIMethods.TECDOC_GETVEHICLELISTIMMEDIATEATTRIBUTS,
        payload: {
          lang,
          articleCountry,
          linkedArticlePairs: {
            array: tempVehicleIds,
          },
          linkingTargetType: "VOL",
          country: articleCountry,
          immediateAttributs: true,
          articleId: articleId,
        },
      }

      const [result, error]: any = await resultOrError<QueryResponse<any>>(
        call(
          "TecDoc",
          {
            ...body,
          },
          "Continental_Aftermarket"
        )
      )

      if (result) {
        lastResult = result
        detailedVehicleArray = detailedVehicleArray.concat(result.data.array)
      } else {
        runInAction(() => {
          this.loadingVehicleImmediateAttributs = false
        })
        return {
          status: ActionResultStatus.ERROR,
          error: error,
        } as ActionError
      }
    }
    if (detailedVehicleArray && lastResult) {
      runInAction(() => {
        this.vehicleListImmediateAttributs = detailedVehicleArray
        this.loadingVehicleImmediateAttributs = false
      })
      return {
        status: ActionResultStatus.SUCCESS,
        result: lastResult,
      } as ActionSuccess<number>
    }
  }
}

export default TecDocStore
