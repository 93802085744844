import React, { createContext, useContext } from "react"

import Store from "./store"

/* 
CONTEXT / PROVIDER INIT
*/

const TecDocStoreContext = createContext<Store | null>(null)

export const StoreProvider: React.FC = (props) => {
  const { children } = props

  return (
    <TecDocStoreContext.Provider value={new Store()}>
      {children}
    </TecDocStoreContext.Provider>
  )
}

/* 
HOOK DEFINITION
*/

export const useTecDocStore = () => useContext(TecDocStoreContext)
