import React, { useEffect, useState } from "react"
import { useHistory, generatePath } from "react-router-dom"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import {
  Box,
  Container,
  FormControl,
  Typography,
  useTheme,
  Link,
} from "@mui/material"
import { default as image_mappcode_2 } from "../../assets/images/image_mappcode_2.png"
import { default as image_mappcode_qr_ean } from "../../assets/images/image_mappcode_qr_ean.png"
import en from "./locales/en.json"
import ResponsiveBackground from "components/ResponsiveBackground"
import { observer } from "mobx-react"
import TextField from "@mui/material/TextField"
import ContiSpecificButton from "components/ContiSpecificButton"
import useScopedTranslation from "hooks/useScopedTranslation"
import BarcodeScannerComponent from "react-qr-barcode-scanner"
import {
  ateMappCodeQrEan,
  contiMappCode,
  contiMappCodeQrEan,
  isAte,
  isConti,
} from "utils/Global"
import { scrolltToTop } from "utils/util"
import Dialog from "components/Dialog"

// use this namespace also for subcomponents and for smaller subpages
export const namespace = __filename

const MAPPCodeScreen = () => {
  const { t } = useScopedTranslation(
    namespace,
    (locale) => import(`./locales/${locale}.json`),
    en
  )

  const [data, setData] = React.useState(() =>
    localStorage.getItem("data") ? localStorage.getItem("data") : ""
  )
  const theme = useTheme()

  const isMobile = window.innerWidth < theme.tokens.breakpoints.breakpointTablet

  const [scan, setScan] = React.useState(false)
  const [mappCodeImg, setMapCodeImg] = React.useState(image_mappcode_2)
  const [eanCodeImg, setEanCodeImg] = React.useState(image_mappcode_qr_ean)
  const [show, setShow] = React.useState(false)
  const [showInfo, setShowInfo] = React.useState(null)
  const [showPlaceholder, setShowPlaceholder] = React.useState<boolean>(true)
  const [showTextField, setShowTextField] = React.useState<boolean>(
    !isMobile ? true : false
  )

  const history = useHistory()

  const [productCode, setProductCode] = useState("")
  const [tempProductCode, setTempProductCode] = useState("")

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      const finalProductCode = tempProductCode ? tempProductCode : productCode
      sessionStorage.setItem("productCode", finalProductCode)
      history.push("/authenticity")
    }
  }

  const onClickHandle = () => {
    const finalProductCode = tempProductCode ? tempProductCode : productCode
    sessionStorage.setItem("productCode", finalProductCode)
    history.push("/authenticity")
  }

  const redirectLocation = (gtin) => {
    history.push(generatePath("/tecdoc"))
  }

  const handleChange = (e) => {
    const inputValue = e.currentTarget.value

    if (
      (!inputValue.includes("(21)") && !inputValue.includes("(01)")) ||
      inputValue.length < 13
    ) {
      setTempProductCode(`(01)04068267000184(21)${inputValue}`)
      setProductCode(inputValue)
    } else {
      setProductCode(inputValue)
    }
  }

  useEffect(() => {
    if (isAte) {
      setEanCodeImg(ateMappCodeQrEan)
      setMapCodeImg(contiMappCode)
    } else if (isConti) {
      setEanCodeImg(contiMappCodeQrEan)
      setMapCodeImg(contiMappCode)
    } else {
      setEanCodeImg(contiMappCodeQrEan)
      setMapCodeImg(contiMappCode)
    }
    scrolltToTop()
  }, [])

  const handleShowInfo = () => {
    setShowInfo(!showInfo)
  }

  return (
    <ResponsiveBackground theme={theme}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          minHeight: "40vh",
        }}
      >
        {isMobile ? (
          <Typography> {t("textEtikett")}</Typography>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              flexDirection: "column",
              padding: "0px 20px",
            }}
          >
            <Box>
              {window.innerWidth <
                theme.tokens.breakpoints.breakpointTablet && (
                <>
                  <Box>
                    <Typography padding={"15px 0px"}>
                      {t("mappCodeText")}
                    </Typography>
                  </Box>
                  <Box
                    width={
                      window.innerWidth <
                      theme.tokens.breakpoints.breakpointTablet
                        ? "243px"
                        : "100%"
                    }
                    height={
                      window.innerWidth <
                      theme.tokens.breakpoints.breakpointTablet
                        ? "45px"
                        : null
                    }
                  >
                    <ContiSpecificButton
                      path="/mappcodescreen"
                      text={"QR-Code scannen"}
                      isScannIcon={true}
                      onClick={() => setScan(true)}
                    ></ContiSpecificButton>

                    <Dialog
                      open={scan || show}
                      onClose={() => {
                        setShow(false)
                        setScan(false)
                        localStorage.removeItem("data")
                      }}
                      closeStyle={{
                        color: theme.palette.text.primary,
                        label: t("close"),
                      }}
                      confirmStyle={{
                        color: theme.palette.primary.main,
                        label: t("confirm"),
                      }}
                      onAbort={() => localStorage.removeItem("data")}
                      onAccept={
                        show
                          ? () => {
                              localStorage.removeItem("data")
                              redirectLocation(data)
                            }
                          : null
                      }
                      title={show ? t("codeScanned") : t("scanCode")}
                      text={show ? data : ""}
                      content={
                        scan && (
                          <BarcodeScannerComponent
                            onUpdate={(err, result) => {
                              const text = result?.getText()
                              if (!!result) {
                                if (text.match(/^[0-9]+$/) != null) {
                                  if (!localStorage.getItem("data")) {
                                    setData(text)
                                    localStorage.setItem("data", text)
                                    setShow(true)
                                    setScan(false)
                                  }
                                }
                              }
                            }}
                          />
                        )
                      }
                    />
                  </Box>
                </>
              )}
              <Box sx={{}}>
                <FormControl fullWidth>
                  {window.innerWidth <
                  theme.tokens.breakpoints.breakpointTablet ? (
                    <Link
                      onClick={() => setShowTextField(!showTextField)}
                      color="#000"
                    >
                      <Typography sx={{ margin: "15px 0px" }}>
                        {t("manualEntry")}
                      </Typography>
                    </Link>
                  ) : (
                    <Typography sx={{ marginBottom: "15px" }} variant={"body1"}>
                      {t("helpTextTablet")}
                    </Typography>
                  )}
                  <Box
                    sx={{
                      display: showTextField ? "flex" : "none",
                      flexDirection: "row",
                      flexFlow:
                        window.innerWidth <
                        theme.tokens.breakpoints.breakpointTablet
                          ? "row wrap"
                          : null,
                      alignItems: "center",
                    }}
                  >
                    <TextField
                      fullWidth={
                        window.innerWidth >
                        theme.tokens.breakpoints.breakpointTablet
                      }
                      id="outlined-basic"
                      variant="outlined"
                      placeholder={showPlaceholder ? "965865841253" : ""}
                      value={productCode}
                      inputProps={{ min: 0, style: { textAlign: "center" } }}
                      InputProps={{
                        style: {
                          margin: "15px 10px",
                          borderRadius: "30px",
                          textAlign: "center",
                          fontFamily: isAte
                            ? "Etelka Light"
                            : "ContinentalStagSans-Light",
                          fontSize: "18px",
                          lineHeight: "26px",
                          letterSpacing: "3px",
                          width: "100%",
                          height:
                            window.innerWidth <
                            theme.tokens.breakpoints.breakpointTablet
                              ? "32px"
                              : undefined,
                          backgroundColor: "#ffff",
                          minWidth: "250px",
                        },
                      }}
                      style={{}}
                      onChange={handleChange}
                      onKeyPress={handleKeyPress}
                      onFocus={() => setShowPlaceholder(false)}
                      onBlur={() => setShowPlaceholder(true)}
                    />
                    <Box sx={{ margin: "15px 20px" }}>
                      <ContiSpecificButton
                        onClick={onClickHandle}
                        isForwardIcon={true}
                        width="30px"
                        isFlexFlow={true}
                      ></ContiSpecificButton>
                    </Box>
                  </Box>
                </FormControl>
              </Box>

              <Box />
            </Box>

            <Box sx={{ padding: "30px 0px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <InfoOutlinedIcon onClick={handleShowInfo} />
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "24px",
                    marginLeft: "5px",
                  }}
                >
                  {t("infoMappCode")}
                </Typography>
              </Box>
              <Box sx={{ padding: "10px 10px" }}>
                {showInfo && (
                  <>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "300",
                        lineHeight: "24px",
                      }}
                    >
                      {t("exampleLabelText")}
                    </Typography>
                    <img
                      src={eanCodeImg}
                      width="70%"
                      height={"70%"}
                      alt=""
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "0 auto",
                        padding: "10px 0px",
                        maxWidth: "400px",
                      }}
                    ></img>

                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "300",
                        lineHeight: "24px",
                      }}
                    >
                      {t("newLabelText")}
                    </Typography>
                    <img
                      src={mappCodeImg}
                      width="70%"
                      height={"70%"}
                      alt=""
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "0 auto",
                        padding: "10px 0px",
                        maxWidth: "400px",
                      }}
                    ></img>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "300",
                        lineHeight: "24px",
                      }}
                    >
                      {t("olderLabelText")}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        )}
      </Container>
    </ResponsiveBackground>
  )
}

export default observer(MAPPCodeScreen)
