import React from "react"

export const globalEndpoint = "Continental Widget" //"Generic 1D+ MRP"

export enum ERoute {
  ROOT = "/",
  COUNTERFEIT = "/report",
  SECONDSCAN = "/scan",
  AUTHENTICITY_CHECK = "/authenticity",
  MAPPCodeScreen = "/mappcodescreen",
  EANCodeScreen = "/eancodescreen",
  TECDOC = "/tecdoc",
  TECRMI = "/tecrmi",
  IMPRINT = "/imprint",
  TERMSOFUSE = "/termsofuse",
}

export type TRoute = {
  path: ERoute
  Icon?: React.FC | JSX.Element
  Component: React.FC // React components have to be written in Capitalized!
}

export type PathParams = {} | { [i: string]: string }

export enum ActionResultStatus {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export type ActionSuccess<T> = {
  status: ActionResultStatus.SUCCESS
  result: T
}
export type ActionError = {
  status: ActionResultStatus.ERROR
  error: any
  knownErrors: { [key: string]: string }
}

export interface QueryResponse<T> {
  totalCount?: number
  results?: Array<T>
}
