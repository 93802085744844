import { ListTextItemTypes } from "./TecDoc/components/List"

export const createItemBorder = (array: ListTextItemTypes[]) => {
  if (array.length > 0) {
    array.map((item, index) => {
      return (item.hasBorder = array.length - 1 !== index)
    })
  }
  return array
}

export const groupListByValue = (list, value) => {
  const groupedList: { key: [] } = Object()
  list.forEach((item) => {
    if (item[value] in groupedList) {
      groupedList[item[value]].push(item)
    } else {
      groupedList[item[value]] = [item]
    }
  })
  return groupedList
}

export function uniq(a) {
  let seen = {}
  return a.filter(function (item) {
    return seen.hasOwnProperty(item.key) ? false : (seen[item.key] = true)
  })
}

export const getDate = (from, to) => {
  let fromString =
    from.toString().slice(4, 6) + "." + from.toString().slice(0, 4)
  let toString = ""

  if (to !== undefined) {
    toString =
      " - " + to.toString().slice(4, 6) + "." + to.toString().slice(0, 4)
  }
  return fromString + toString
}

export const getDateSingle = (date) => {
  return date.toString().slice(4, 6) + "." + date.toString().slice(0, 4)
}

export const isValidUrl = (string) => {
  const expression =
    /[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&/=]*)?/gi
  const urlRegex = new RegExp(expression)
  const dateRegex = new RegExp(
    "^(0[1-9]|1[0-2])\\.[0-9]{4}( - (0[1-9]|1[0-2])\\.[0-9]{4})?$"
  )
  const numberRegex = new RegExp("^(\\d+(\\.\\d+)*(-\\d+(\\.\\d+)*)*)$")
  if (string.match(dateRegex) !== null || string.match(numberRegex) !== null) {
    return false
  }

  return string.match(urlRegex) !== null
}
