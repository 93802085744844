import * as React from "react"
import { StyledBox, StyledImage } from "./styles"
import { useEffect, useState } from "react"
import { calculateImageSize } from "./util"

const Image = ({ image, size }) => {
  const [imageSize, setImageSize] = useState(0)

  useEffect(() => {
    let isMounted = true
    if (image) {
      if (size) {
        setImageSize(size)
      } else {
        calculateImageSize(image).then((response) => {
          if (isMounted) setImageSize(response)
        })
      }
    }
    return () => {
      isMounted = false
    }
  }, [image, size])

  return (
    <StyledBox>
      <StyledImage
        height={size ? `${imageSize}%` : ""}
        width={`${imageSize}%`}
        src={image}
      />
    </StyledBox>
  )
}

export default Image
