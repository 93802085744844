import { Tabs as MuiTabs } from "@mui/material"
import React, { useEffect } from "react"

import { TabsTypes, HorizontalLabels, VerticalLabels } from "./types"
import { observer } from "mobx-react"

import { HorizontalTab, VerticalTab, TabPanel } from "./components"
import { classes, TabBox } from "./styles"
import tokens from "themes/default/tokens"

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const Tabs: React.FC<TabsTypes> = (props) => {
  const { labels, components, variant, icons, isHomeSelected } = props
  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const tabHeaders = []
  const tabComponents = []

  useEffect(() => {
    if (isHomeSelected && value !== 0) setValue(0)
  }, [isHomeSelected, value])

  let castLabels
  // determine type of labels
  if ((labels as unknown as HorizontalLabels) && variant === "horizontal") {
    castLabels = labels as unknown as HorizontalLabels
  } else {
    castLabels = labels as unknown as VerticalLabels
  }

  for (let i = 0; i < labels.length; i++) {
    if (variant === "vertical") {
      tabHeaders.push(
        <VerticalTab
          key={`tab-item-${castLabels[i].label}-${Math.random() * 10}`}
          icon={icons[i]}
          label={castLabels[i]}
          {...a11yProps(i)}
        />
      )
    } else {
      tabHeaders.push(
        <HorizontalTab
          sx={{ minWidth: 0 }}
          key={`tab-item-${castLabels[i].label}-${Math.random() * 1000}`}
          label={castLabels[i].label}
          icon={icons[i]}
          {...a11yProps(i)}
        />
      )
    }
  }

  for (let i = 0; i < components.length; i++) {
    tabComponents.push(
      <TabPanel
        key={`tab-panel-${i}`}
        value={value}
        index={i}
        hasPadding={components[i].hasPadding}
      >
        {components[i].element}
      </TabPanel>
    )
  }

  return (
    <>
      <TabBox key="tab-box" className={classes[variant]}>
        <MuiTabs
          orientation={variant === "vertical" ? "vertical" : "horizontal"}
          TabIndicatorProps={{
            style: {
              background:
                variant === "vertical" ? "transparent" : tokens.color.primary,
              height: "5px",
            },
          }}
          value={value}
          onChange={handleChange}
          aria-label={`${variant} tabs`}
          variant={"fullWidth"}
        >
          {tabHeaders}
        </MuiTabs>
      </TabBox>
      {tabComponents}
    </>
  )
}

export default observer(Tabs)
