import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { styled } from "@mui/system"
import en from "./locales/en.json"
import { useHistory } from "react-router-dom"
import { Box, Container, Typography, useTheme } from "@mui/material"
import ResponsiveBackground from "components/ResponsiveBackground"
import useScopedTranslation from "../../hooks/useScopedTranslation"
import ContiSpecificButton from "components/ContiSpecificButton"
import { useTecDocStore } from "../../api/services/TecDoc"
import { generatePath } from "react-router-dom"
import { isDevEnv } from "utils/env"
import { default as Conti_original } from "../../assets/images/Continental_Original.png"
import { default as Ate_original } from "../../assets/images/Ate_Original.jpg"

import { default as Conti_vehicle_use } from "../../assets/images/CONTI_Loyalty_Program_Werbetrailer_sec22.jpg"
import { default as Ate_vehicle_use } from "../../assets/images/ATE_Loyalty_Program_Werbetrailer_sec22.jpg"
import { basePath, isAte } from "utils/Global"
import { convertSizeRem, scrolltToTop } from "utils/util"

// use this namespace also for subcomponents and for smaller subpages
export const namespace = __filename

const StartScreen = () => {
  const { t } = useScopedTranslation(
    namespace,
    (locale) => import(`./locales/${locale}.json`),
    en
  )

  const theme = useTheme()
  const history = useHistory()
  const tecDocStore = useTecDocStore()

  const OriginalBackground = styled("div")({
    position: "relative",
    width: "100%",
    height: "200px",
    overflow: "hidden",
    background: `url(${
      !isAte ? Conti_original : basePath + Ate_original
    }) center/cover`,
    "&:before": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.6)", // Adjust the alpha value (last parameter) for darkness
      filter: "blur(5px)",
    },
  })

  const VehicleUsageBackground = styled("div")({
    position: "relative",
    width: "100%",
    height: "200px",
    overflow: "hidden",
    background: `url(${
      !isAte ? Conti_vehicle_use : basePath + Ate_vehicle_use
    }) center/cover`,
    "&:before": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.6)", // Adjust the alpha value (last parameter) for darkness
      filter: "blur(5px)",
    },
  })

  const OverlayContent = styled("div")({
    position: "absolute",
    display: "grid",
    placeItems: "center",
    height: "70%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    padding: "10px",
    width:
      window.innerWidth > theme.tokens.breakpoints.breakpointTablet
        ? "280px"
        : "100%",
  })

  useEffect(() => {
    const paramGtin = "gtin"
    const paramCode = "code"
    tecDocStore.reset()
    sessionStorage.clear()
    console.log(":::isDevEnv()", isDevEnv())

    if (!isDevEnv()) {
      console.log(":::document.referrer", document.referrer)
      const referrerSearchParams = new URLSearchParams(
        new URL(document.referrer).search
      )
      if (
        referrerSearchParams.has(paramGtin) &&
        referrerSearchParams.has(paramCode)
      ) {
        history.push(generatePath("/authenticity"))
      } else {
        console.error("Does not exist in the referrer URL")
      }
    } else {
      const url = new URL(window.location.href)
      const queryParams = url.searchParams
      const gtin = queryParams.get(paramGtin)
      const code = queryParams.get(paramCode)
      if (gtin && code) {
        sessionStorage.setItem("gtin", gtin)
        sessionStorage.setItem("code", code)
        history.push(generatePath("/authenticity"))
      } else {
        console.error("Does not exist in the href URL")
      }
    }
    scrolltToTop()
    sessionStorage.removeItem("productCode")
    sessionStorage.setItem("vasImage", null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ResponsiveBackground theme={theme}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          padding: "3px",
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "center", padding: "10px" }}
        >
          <Typography>{t("subTitle")}</Typography>
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "center", padding: "10px" }}
        >
          <OriginalBackground>
            <OverlayContent>
              <Typography
                variant={
                  window.innerWidth < theme.tokens.breakpoints.breakpointTablet
                    ? "h6"
                    : "h5"
                }
                fontWeight={
                  window.innerWidth > theme.tokens.breakpoints.breakpointTablet
                    ? 700
                    : 600
                }
                fontSize={convertSizeRem(1.5)}
                color={theme.tokens.color.conti_typo}
                marginBottom="10px"
                marginTop="10px"
                sx={{
                  textShadow:
                    "2px 2px 20px rgba(0, 0, 0, 1), -2px -2px 20px rgba(0, 0, 0, 1)",
                }}
              >
                {t("screenText1")}
              </Typography>
              <ContiSpecificButton
                path="/mappcodescreen"
                text={t("buttonText")}
                isForwardIcon={true}
                width="100px"
              ></ContiSpecificButton>
            </OverlayContent>
          </OriginalBackground>
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "center", padding: "10px" }}
        >
          <VehicleUsageBackground>
            <OverlayContent>
              <Typography
                variant={
                  window.innerWidth < theme.tokens.breakpoints.breakpointTablet
                    ? "h6"
                    : "h5"
                }
                fontWeight={
                  window.innerWidth > theme.tokens.breakpoints.breakpointTablet
                    ? 700
                    : 600
                }
                fontSize={convertSizeRem(1.5)}
                color={theme.tokens.color.conti_typo}
                marginBottom="10px"
                marginTop="10px"
                sx={{
                  textShadow:
                    "2px 2px 10px rgba(0, 0, 0, 1), -2px -2px 10px rgba(0, 0, 0, 1)",
                }}
              >
                {t("screenText2")}
              </Typography>
              <ContiSpecificButton
                path="/scan"
                text={t("buttonText")}
                isForwardIcon={true}
                isOvverride={true}
                width="100px"
              ></ContiSpecificButton>
            </OverlayContent>
          </VehicleUsageBackground>
        </Box>
      </Container>
    </ResponsiveBackground>
  )
}

export default observer(StartScreen)
