import React, { Suspense } from "react"
import { SnackbarProvider } from "notistack"
import { HTML5Backend } from "react-dnd-html5-backend"
import { DndProvider } from "react-dnd"

import { BrowserRouter } from "react-router-dom"

import services from "api/services"

import { CssBaseline } from "@mui/material"
import { ThemeProvider } from "@mui/material/styles"
import StylesProvider from "@mui/styles/StylesProvider"

import AppComponent from "pages/App/index"
import { osapiens } from "themes"

import { FALLBACK_LANGUAGE, setup } from "i18n"

import "./App.css"

const theme = osapiens.light

const PREFIX = "App"

const classes = {
  success: `${PREFIX}-success`,
  error: `${PREFIX}-error`,
  warning: `${PREFIX}-warning`,
  info: `${PREFIX}-info`,
}

const CombinedStoreProvider: React.FC<{ provider: React.FC<any>[] }> = ({
  provider,
  children,
}) => {
  const InitialNode: React.FC<{}> = ({ children }) => <>{children}</>
  const NestedProvider = provider.reduce((Provider, ParentNode) => {
    return ({ children }) => (
      <ParentNode>
        <Provider>{children}</Provider>
      </ParentNode>
    )
  }, InitialNode)

  return <NestedProvider>{children}</NestedProvider>
}

const AppContainer = () => {
  React.useEffect(() => {
    // initialize i18n with fallback language if not done, yet
    setup(FALLBACK_LANGUAGE)
  }, [])

  return (
    <Suspense fallback={<div>loading...</div>}>
      <CombinedStoreProvider provider={services || []}>
        <SnackbarProvider
          maxSnack={3}
          classes={{
            variantSuccess: classes.success,
            variantError: classes.error,
            variantWarning: classes.warning,
            variantInfo: classes.info,
          }}
        >
          <DndProvider backend={HTML5Backend}>
            <StylesProvider injectFirst>
              <ThemeProvider theme={theme}>
                {/* Kickstart a simple scoped CSS baseline to build upon. */}
                {/* Required to override Material-UI's styles via CSS modules. */}
                <CssBaseline />
                <BrowserRouter>
                  <AppComponent />
                </BrowserRouter>
              </ThemeProvider>
            </StylesProvider>
          </DndProvider>
        </SnackbarProvider>
      </CombinedStoreProvider>
    </Suspense>
  )
}

export default AppContainer
