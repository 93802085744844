import * as React from "react"
import { observer } from "mobx-react"

import { Box, CircularProgress, useTheme } from "@mui/material"
import { useEffect } from "react"
import { useTecDocStore } from "api/services/TecDoc"
import List from "../List/List"
import { useMemo } from "react"
import { ListTextItemTypes } from "../List"
import i18next from "i18next"
import ListHeading from "../List/components/ListHeading"
import useScopedTranslation from "../../../../hooks/useScopedTranslation"
import en from "./locales/en.json"
import { createItemBorder, groupListByValue } from "pages/util"

export const namespace = __filename

const CountrySelection: React.FC<{ backToHome: () => void }> = (props) => {
  const lang = i18next.language
  const { t } = useScopedTranslation(
    namespace,
    (locale) => import(`./locales/${locale}.json`),
    en
  )
  const theme = useTheme()

  const { backToHome } = props
  const tecDocStore = useTecDocStore()
  const { countryList, loadingCountryList } = tecDocStore

  useEffect(() => {
    if (!countryList || countryList?.length === 0) {
      tecDocStore
        .fetchCountries(lang)
        .catch((err) => console.error("Error fetching country details", err))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tecDocStore, lang])

  const countryItems = useMemo(() => {
    let tempCountryItems: ListTextItemTypes[] = []
    if (countryList) {
      tempCountryItems = countryList.map((item) => {
        return {
          firstLetter: item.countryName.charAt(0),
          key: item.countryCode,
          subItems: [
            {
              label: item.countryName,
              content: "",
              docType: "",
            },
          ],
          onClick: () => {
            localStorage.setItem("countryCatalogueCode", item.countryCode)
            window.location.reload()
          },
        }
      })
    }
    return Object.entries(
      groupListByValue(tempCountryItems, "firstLetter")
    ).map((item) => {
      return (
        <List
          type={"TEXT"}
          key={item[0]}
          title={item[0]}
          items={createItemBorder(item[1])}
        />
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryList, backToHome])

  return loadingCountryList ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="10vh"
      sx={{ flexDirection: "column" }}
    >
      <CircularProgress color={"primary"} size="5vh" />
    </Box>
  ) : (
    <>
      <Box sx={{ paddingBlock: theme.spacing(2), color: "white" }}>
        <ListHeading
          title={t("selection")}
          caret={"NONE"}
          variant={"PRIMARY"}
        />
      </Box>
      <Box>{countryItems}</Box>
    </>
  )
}

export default observer(CountrySelection)
